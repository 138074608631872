#dashboard{ 
    border: 0px solid black;
    font-size: 12px;
    max-width: 960px;
    margin: auto auto;
    padding-top: 80px;

    @media screen and (max-width: $breakPointS) {
        padding-top: 30px;
    }

    .button{
        min-width: 200px;
        height: 30px;
        display: inline-block;
        cursor: pointer;
        background-color: #2daafa;
        border: 1px solid #8f8f8f;
        padding: 9px 5px 5px 5px;
        font-size: 22px;
        color: white;
        text-align: center;
    }

    .transactions .buttonHolder{
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
        div{
            border: 1px solid $text-color-robo;
            margin: 10px;
            font-size: 19px;
            a{
                padding: 12px 5px 13px 5px;
                min-width: 250px;
                display: inline-block;
                cursor: pointer;
                color: $text-color-robo;
            }
        }
        
        .button{
            border: 1px solid #2a1340;
            margin: 10px;
            font-size: 19px;
            min-width: inherit;
            font-size: 12px;
            height: inherit;
            background-color: transparent;
            color: #2a1340;
            padding: 10px 14px 10px 14px;
            margin-right: 0px;
            border: 1px solid #2a1340;
        }        
        
    }

    .addOptions{
        display: flex;
        width: 100%;
        font-family: 'museo';
        .responsive{display: none;}
        

        >div{
            width: 312px;
            margin-right:12px; 
            text-align: center;
            background-color: #e8e5ea;
            display: inline-table;
            min-height: 315px;

            .button{
                cursor: auto;
                font-family: 'Roboto',Arial,sans-serif;
                background-color: #e8e5ea;
                display: block;
                border: 0px;
                color: $text-color-robo;
                font-size: 20px;
                margin-bottom: 0px;
                height: auto;
                text-align: left;
                padding: 9px 12px 12px 12px;
                >span{
                    display: block;
                    >span{ 
                        display: block;
                        height: 29px;
                        padding: 6px 0px 0px 5px;
                        padding-right: 10px;
                        border: 1px solid $text-color-robo;
                        margin-top:11px;
                        background-image: url(/images/addoptions_corner.svg);
                        background-repeat: no-repeat;
                        background-position: right bottom;
                        background-size: 28px;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                }
                >div{font-size: 16px; margin-top: 5px;}
            }

            >div:nth-of-type(1){
                background-color: $text-color-robo;
                color: white;
                height: 35px;
                >span{
                    background-image: url(/images/icons/single_account.svg);
                    background-size: 30px 20px;
                    background-position-y: 7px;                    
                    background-repeat: no-repeat;
                    height: 35px;
                    display: inline-block;
                    font-size: 19px;
                    padding-left: 40px;
                    padding-top: 6px;
                }
            }
            >div.add{
                //height: 155px;
            }
        }

        >div:nth-of-type(2){
            >div:nth-of-type(1){
                span{
                    background-image: url(/images/icons/joined_account.svg);
                }
            }
        }

        >div:nth-of-type(3){
            margin-right: 0px;
            >div:nth-of-type(1){
                span{
                    background-image: url(/images/icons/child_account.svg);
                }
            }
        }

        @media screen and (max-width: $breakPointM) {
            //>div{display: none}
            flex-direction: column;
            >div{
                min-height: auto;
                width: 100%;
                >div{
                    >span{
                        >span{
                            display: block;
                        }
                    }
    
                }
            }
/*
            >div.responsive{
                display: block; width: 100%; padding-right: 0px;
                span{background-image: none!important; padding-left: 0px!important;}
            }
            */
        }

    }

    .depotbox{
        padding-bottom: 10px;

        .top{
            padding: 20px;
            background-color: $quirion-dark-blue;
            color: white;

            .button{
                background-color: transparent;
                width: 140px;
                min-width: inherit;
                height: 25px;
                padding: 10px 10px 0px 0px;
                font-size: 12px;
                border: 1px solid white;
                &::after{
                    content: '+';
                    font-size: 25px;
                    position: absolute;
                    margin-top: -8px;
                    margin-left: 5px;
                }
            }

            >.first{
                //background-image: url(/images/bg_single.png);
                background-image: url(/images/icons/single_account.svg);
                background-size: 21px;
                
                background-repeat: no-repeat;
                background-position: left;
                padding-left: 40px;
                display: flex;
                margin-bottom: 15px;

                .button{
                    height: inherit;
                    padding: 9px 8px 10px 0px;
                }

                >div:nth-of-type(1){

                    flex-grow: 8;
                    width: 64px;
                    >div:nth-of-type(1){
                        font-size: 12px;
                        padding-bottom: 1px;
                    }

                    >div:nth-of-type(2){
                        font-size: 16px;
                        font-family: 'Museo';
                        font-weight: bold;
                    }
                }

                >div:nth-of-type(2){
                    flex-grow: 1;
                    >div:nth-of-type(1){
                        font-size: 12px;
                        padding-bottom: 1px;
                    }

                    >div:nth-of-type(2){
                        font-size: 16px;
                        font-family: 'Museo';
                        font-weight: bold;
                    }
                }

                >div:nth-of-type(3){
                    flex-grow: 1;
                    text-align: right;
                }
                
                @media screen and (max-width: $breakPointS) {
   
                    flex-wrap: wrap;                    
                    margin-bottom: 0px;
                    >div{ margin-bottom: 15px; margin-right: 10px; }
                    >div:nth-of-type(3){
                        text-align: center;
                        margin-right: 40px;
                        width: 100%;
                    }
                }
            }

            .wrapper_chart{
                display: flex;
                margin-top: 0px;
                
                @media screen and (max-width: $breakPointS) {
                    flex-direction: column;
                }
                
                .icon_info{
                    &::after{
                        cursor: pointer;
                        content: '';
                        background : url(/images/icons/info.svg) no-repeat;
                        margin-left: 5px;
                        padding-right: 15px;
                        background-size: 13px;
                        background-position-y: 3px;
                    }
                }
                
                .table{ width:100%;}
                
                .button{
                    width: 137px;
                    min-width: inherit;
                    height:10px;
                    padding: 10px 10px 13px 0px;
                    margin-top: 0px;
                    margin-right: 3px;
                    border: 1px solid white;
                    min-width: inherit;
                    &::after{
                        content: '>';
                        margin-top: -4px;
                        font-family: Consolas,Menlo,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New,monospace,sans-serif;
                        font-size: 22px;
                    }
                }

                .left{
                    display: flex;
                    flex-direction: row;
                    //min-width: 70%;
                    
                    .table-group{
                        >div.label{
                            background-color: transparent!important;
                            color: white;
                            text-align: left!important;
                            font-size: 13px;
                            margin-top: 5px;
                            padding-top: 6px!important;
                            padding-bottom: 3px!important;
                        }
                    }                    
                    
                    .first{
                        display: flex;
                        flex-direction: column;
                        color: $quirion-dark-blue;
                        min-width: 290px;
                        padding-right: 10px;
                        
                        @media screen and (max-width: $breakPointS) {
                            padding-right: 0px;
                        }

                        .clearingValue{ 
                            padding-top: 3px;
                            a{
                                color: white;
                                text-decoration: none;
                            }
                        }
                        
                        >div:nth-of-type(1){
                            display: flex;
                            flex-direction: column;
                            //height: 45px;
                            
                            color: white;
                            //border-right: 3px solid $quirion-dark-blue;

                            >div:nth-of-type(1){
                                flex-grow: 1;
                                font-size: 13px;
                                //padding: 16px;
                                margin-bottom: 4px;
                            }
                            >div:nth-of-type(2){
                                color: $quirion-dark-blue;
                                background-color: $quirion-light-grey;
                                font-family: 'museo';
                                flex-grow: 1;
                                text-align: center;
                                font-size: 26px;
                                font-weight: bold;
                                padding: 8px 11px;
                            }
                        }
                        >div:nth-of-type(2){
                            display: flex;
                            width: 100%;
                            flex-direction: column;
                            font-size: 17px;
                            margin-top: 10px;
                            
                            >div{
                                display: flex;
                                >div:nth-of-type(1),
                                >div:nth-of-type(2){

                                    background-color: $quirion-dark-grey;
                                    border-right: 3px solid $quirion-dark-blue;
                                    //border-bottom: 3px solid $quirion-dark-blue;
                                    //min-width: 87px;
                                    //height: 20px;
                                    text-align: center;
                                    font-family: 'museo';
                                    padding: 4px 0px;
                                    }

                                >div:nth-of-type(2){
                                min-width: 104px;
                                border-right:0px;
                                }

                                >div:nth-of-type(3){
                                    color: white;
                                    font-size: 13px;
                                    padding-top: 3px;
                                    padding-left: 2px;
                                }
                            }
                            >div:nth-of-type(2){
                                >div:nth-of-type(1){
                                    border-right: 3px solid $quirion-dark-blue;
                                    background-color: $quirion-light-grey;                                    
                                }
                                >div:nth-of-type(2){
                                    background-color: $quirion-light-grey;
                                }
                                >div:nth-of-type(3){
                                    color: white;
                                }
                                div{ border-bottom: 0px;}
                            }
                            
                            // temporary disabled
                            >div:nth-of-type(2) >div:nth-of-type(1){ border-right: 3px solid $quirion-light-grey;}
                            >div:nth-of-type(2) >div:nth-of-type(2), 
                            >div:nth-of-type(3),
                            >div:nth-of-type(4){ display: none!important;}
                            
                        }
                    }
                    /*
                    @media screen and (max-width: $breakPointM) {
                        .first,
                        .second{
                            min-width: 50%;
                            >div:nth-of-type(1){
                                margin-bottom: 15px;
                            }
                        }
                    }*/
                    @media screen and (max-width: $breakPointS) {
                        flex-direction: column;
                        .second{
                            margin-top: 30px;
                        }
                    }
                    
                }
                .right{
                    //min-width: 66%;
                    width:100%;
                    >div:nth-of-type(1){
                        margin-bottom: 4px;
                        //height: 80px!important;
                    }
                    .chart{
                        width: 619px;
                        margin-bottom: 12px;
                        >div{
                            height: 106px!important;   
                            border-left: 1px solid white;
                            border-bottom: 1px solid white;
                        }
                    }
                    .chartControl{
                        text-align: center;
                        .button{
                            width: 113px;
                            padding: 4px 0px;
                            font-size: 15px;
                            height: inherit;
                            &::after{
                                display: none;                            
                            }
                            &.active{
                                background-color: white;
                                color: $quirion-dark-blue;
                            }
                        }
                    }

                    @media screen and (max-width: $breakPointM) {
                        .chart{
                            margin: 12px auto;
                            width: 100%;
                        }
                    }
                    @media screen and (max-width: $breakPointS) {
                        margin-top: 20px;
                    }
                }
            }
        }

        &.childJoinedAccount,&.childSingleAccount{
            .top{
                >.first{
                    background-image: url(/images/icons/child_account.svg);
                    background-size: 27px;
                }
            }
        }

        &.joinedAccount{
            .top{
                >.first{
                    background-image: url(/images/icons/joined_account.svg);
                    background-size: 27px;
                }
            }
        }


        

        &.nosync{

            .error{ color: #d01a91}

            .button{
                min-width: 150px;
                margin: 10px;
                font-size: 17px;
                background-color: $quirion-dark-blue;
                height: 17px;
                padding: 7px 2px 12px;
                border-color: $quirion-dark-blue;
            }

            .first{
                padding-left: 40px;
                background-color: $quirion-dark-blue;
                background-position: left;
                color: white;
                min-height: 30px;
                margin-bottom: 0px;
                >div{
                    >div{
                        //font-size: 25px!important;
                        //margin-top: 9px;
                    }
                }
            }

            .top{
                padding: 10px;
            }

            .bottom{
                padding-top: 11px;
                text-align: center;
                flex-direction: column;
                background-color: #f0eef1;
                flex-grow: 1;
                display: flex;
                .headline{
                    font-size: 36px;
                    padding-top: 11px;
                    color: $quirion-dark-blue;
                    font-family: 'museo';
                    font-family: 'museo';
                    font-weight: bold;
                }
                .info{ font-size: 16px; padding: 20px 40px 20px 40px;}
                .col1,.col2,.col3{ width: auto}

                .col2{
                    @media screen and (max-width: $breakPointM) {
                        //display: none;
                    }
                }
            }

            .col1{ padding-bottom: 0px;}
            .col2{ padding-top: 18px;}
            .col3{ font-size: 16px; padding: 15px 40px 20px 40px;}

            .wrapper.steps{
                display: inline-flex;

                @media screen and (max-width: $breakPointM) {
                    display: none;
                }


                flex-direction: column;

                >div:nth-of-type(1){
                    display: inline-flex;
                    width: 716px;
                    margin: auto;
                }
                >div:nth-of-type(2){
                    padding-top: 10px;
                    font-size: 14px;
                    display: inline-flex;
                    width: 856px;
                    margin: auto;
                    >div{
                        width: 170px;
                    }
                }

                .label.attention,
                .label.done{
                    cursor: pointer;
                }

                .step{
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    display: inline-block;
                    border:2px solid $quirion-coplor-step1;
                    font-family: 'museo';
                    font-size: 19px;

                    >span{
                        padding-top: 6px;
                        display: inline-block;
                        &.status{
                            padding: inherit;
                            display: inline-block;
                            height: 17px;
                            width: 17px;
                            position: absolute;
                            margin-top: -7px;
                            margin-left: -1px;
                        }
                    }

                    &.done{
                        cursor: pointer;
                        .status{
                            background-image: url('/images/status_done.svg');
                        }
                    }
                    &.wrong .status{
                        background-image: url('/images/status_wrong.svg');
                    }
                    &.attention{
                        cursor: pointer;
                        .status{
                            background-image: url('/images/status_attention.svg');
                        }
                    }

                    &.step1{
                        border-color: $quirion-coplor-step1;
                    }
                    &.step2{
                        border-color: $quirion-coplor-step2;
                    }
                    &.step3{
                        border-color: $quirion-coplor-step3;
                    }
                    &.step4{
                        border-color: $quirion-coplor-step4;
                    }
                    &.step5{
                        border-color: $quirion-coplor-step5;
                    }
                }

                .line{
                    width: 134px;
                    span{
                        display: block;
                        height: 2px;
                        margin-top: 15px;
                    }

                    &.step1 span{
                        background-image: linear-gradient(to right, $quirion-coplor-step1 , $quirion-coplor-step2);
                    }
                    &.step2 span{
                        background-image: linear-gradient(to right, $quirion-coplor-step2 , $quirion-coplor-step3);
                    }
                    &.step3 span{
                        background-image: linear-gradient(to right, $quirion-coplor-step3 , $quirion-coplor-step4);
                    }
                    &.step4 span{
                        background-image: linear-gradient(to right, $quirion-coplor-step4 , $quirion-coplor-step5);
                    }
                }
            }
        }

        .strategieContainer{
            background-color: #e8e5ea;
            margin-top: 10px;
            .details_container_opener{
                display: flex;
                padding: 20px;

                .pending{
                    background-image: url(/images/status_attention.svg);
                    background-repeat: no-repeat;
                    background-position: right;
                    padding-right: 20px;
                    background-size: 16px 16px;
                    font-size: 16px;
                    font-weight: bold;
                }

                >div{ flex-grow: 1}
                
                >div:nth-of-type(1){
                    flex-grow: 7;
                    width: 190px;
                    padding-right: 20px;
                    >div:nth-of-type(1){
                        font-size: 18px;
                        color: $text-color-robo;
                        font-weight: bold;
                        font-family: 'museo';
                    }
                    >div:nth-of-type(2){
                        display: none;
                        font-size: 12px;
                        color: $details-grey;
                        margin-top: 3px;
                    }
                }

                >div:nth-of-type(2){
                    flex-grow: 2;
                    width: 85px;
                    @media screen and (max-width: $breakPointS) {
                        display: none;
                    }
                    >div:nth-of-type(1){
                        font-size: 18px;
                        color: $color-robo-blue;
                        font-weight: bold;
                        font-family: 'museo';
                    }
                    >div:nth-of-type(2){
                        color: $details-grey;
                        margin-top: 3px;
                    }
                }                
                
                >div:nth-of-type(3){
                    padding-right: 28px;
                    >div:nth-of-type(1){
                        display: flex;
                        justify-content: space-between;
                        font-size: 16px;                        
                        font-family: 'museo';
                        // temporary disabled
                        justify-content: center;
                        >div:nth-of-type(2){ display: none;}
                        
                    }
                    >div:nth-of-type(2){
                        color: grey;
                        text-align: center;
                        margin-top: 5px;
                        &::after {
                            content: '';
                            background: url(/images/icons/info_grey.svg) no-repeat;
                            margin-left: 5px;
                            padding-right: 15px;
                        }                        
                    }
                }
                
                >div:nth-of-type(4){
                    text-align: right;
                    max-width: 290px;
                    min-width: 81px;
                    .button{
                        min-width: inherit;
                        font-size: 12px;
                        height: inherit;
                        background-color: transparent;
                        color: $text-color-robo;
                        padding: 8px 16px;
                        margin-right: 5px;
                        border: 1px solid $text-color-robo;
                        &.important{
                            padding: 8px 20px;
                            background-color: $color-robo-blue;
                            border: 1px solid $color-robo-blue;
                            color: white;
                        }
                        
                        &:last-of-Type{
                            margin-right: 0px;
                        }
                    }
                }
            }
            
            &.active{
                .details_container{
                    display: block;                    
                }
                .details_container_opener{
                    display: none;
                }
            }
            
            .details_container{
                display: none;
                padding: 20px;

                .icon_info{
                    &::after{
                        cursor: pointer;
                        content: '';
                        background : url(/images/icons/info_grey.svg) no-repeat;
                        margin-left: 5px;
                        padding-right: 15px;
                        background-size: 13px;
                        background-position: center top 2px;
                    }
                }
                
                >div:nth-of-type(1){
                    display: flex;
                    >div{ flex-grow: 2}
                    
                    >div:nth-of-type(1),
                    >div:nth-of-type(2){
                        >div:nth-of-type(1){
                            color: $details-grey;
                            font-size: 12px;
                            margin-bottom: 3px;
                        }
                        >div:nth-of-type(2){
                            font-size: 18px;
                            color: $text-color-robo;
                            font-weight: bold;
                            font-family: 'museo';
                        }
                    }

                    >div:nth-of-type(1){
                        padding-right: 20px;
                    }
                    >div:nth-of-type(2){
                        padding-right: 20px;
                        @media screen and (max-width: $breakPointS) {
                            display: none;
                        }
                    }
                    >div:nth-of-type(3){
                        text-align: right;
                        max-width: 115px;
                        .button{
                            min-width: inherit;
                            font-size: 12px;
                            height: inherit;
                            background-color: transparent;
                            color: $text-color-robo;
                            padding: 10px 35px 10px 14px;
                            margin-right: 0px;
                            border: 1px solid $text-color-robo;
                            &::after{
                                content: 'x';
                                font-size: 16px;
                                position: absolute;
                                margin-top: -3px;
                                margin-left: 5px;
                                font-family: Arial, Helvetica, sans-serif;
                                font-weight: normal;
                            }
                        }
                    }
                }
                
                >div:nth-of-type(2){
                    display: flex;
                    margin-top: 25px;
                    
                    >div{
                        width: 33%;
                    }
                    
                    @media screen and (max-width: $breakPointM) {
                        >div:nth-of-type(1),
                        >div:nth-of-type(2){
                            min-width: 50%;
                        }
                        >div:nth-of-type(3){ display: none;}
                    }
                    @media screen and (max-width: $breakPointS) {
                        flex-direction: column;
                        >div{
                            width: 100%;
                            max-width: inherit!important;
                        }
                    }
                    
                    >div:nth-of-type(1){
                        display: flex;
                        flex-direction: column;
                        color: $quirion-dark-blue;
                        max-width: 290px;
                        padding-right: 10px;
                        >div:nth-of-type(1){
                            display: flex;
                            flex-direction: column;
                            //height: 45px;
                            
                            color: white;
                            //border-right: 3px solid $quirion-dark-blue;
    
                            >div:nth-of-type(1){
                                flex-grow: 1;
                                font-size: 13px;
                                //padding: 16px;
                                color: $details-grey;
                                margin-bottom: 4px;
                            }
                            >div:nth-of-type(2){
                                color: $color-robo-blue;
                                background-color: white;
                                font-family: 'museo';
                                flex-grow: 1;
                                text-align: center;
                                font-size: 29px;
                                font-weight: bold;
                                padding: 8px 11px;
                                margin-bottom: 3px;
                            }
                        }
                        >div:nth-of-type(2){
                            display: flex;
                            width: 100%;
                            flex-direction: column;
                            font-size: 17px;
                            
                            >div{
                                display: flex;
                                >div:nth-of-type(1),
                                >div:nth-of-type(2){
    
                                    //background-color: white;
                                    border-right: 3px solid #e8e5ea;
                                    //border-bottom: 3px solid $quirion-dark-blue;
                                    //min-width: 87px;
                                    //height: 20px;
                                    text-align: center;
                                    font-family: 'museo';
                                    padding: 6px 0px;
                                    }
    
                                >div:nth-of-type(2){
                                min-width: 104px;
                                border-right:0px;
                                }
    
                                >div:nth-of-type(3){
                                    color: white;
                                    font-size: 13px;
                                    padding-top: 3px;
                                    padding-left: 2px;
                                }
                            }
                            
                            >div:nth-of-type(1),
                            >div:nth-of-type(3){
                                color: $details-grey;
                                
                                .label{
                                    text-align: left;
                                    font-size: 13px; 
                                    padding-top: 8px;
                                    padding-bottom: 3px;
                                    &.icon_info{
                                        &::after{
                                            cursor: pointer;
                                            content: '';
                                            background : url(/images/icons/info_grey.svg) no-repeat;
                                            margin-left: 5px;
                                            padding-right: 15px;
                                            background-size: 13px;
                                        }
                                    }
                                }
                            }
                            
                            >div:nth-of-type(2),
                            >div:nth-of-type(4){
                                >div:nth-of-type(1){
                                    border-right: 3px solid #e8e5ea;
                                    background-color: white;                                    
                                }
                                >div:nth-of-type(2){
                                    background-color: white;
                                }
                                >div:nth-of-type(3){
                                    color: white;
                                }
                                div{ border-bottom: 0px;}
                            }
                            
                            //temporary disabled
                            >div:nth-of-type(2) >div:nth-of-type(1){ border-right: 3px solid white;}
                            >div:nth-of-type(2) >div:nth-of-type(2),
                            >div:nth-of-type(3),
                            >div:nth-of-type(4){
                                display: none!important;
                            }
                        }
                    }
                    
                    >div:nth-of-type(2){
                        display: flex;
                        flex-direction: column;
                        padding-top: 18px;
                        max-width: 260px;
                        .button{
                            min-width: inherit;
                            font-size: 12px;
                            height: inherit;
                            background-color: transparent;
                            color: $text-color-robo;
                            padding: 10px 35px 10px 14px;
                            margin-right: 0px;
                            margin-bottom: 8px;
                            border: 1px solid $text-color-robo;
                            text-align: left;
                            background-image: url(/images/icons/arrow.svg);
                            background-repeat: no-repeat;
                            background-position: right 10px center;
                            background-size: 13px 13px;
                            text-decoration: none;
                        }
                    }
                    
                    >div:nth-of-type(3){
                        width: 360px;
                    }
                }
                
                ul.nav{
                    display: flex;
                    padding: 20px 0px;
                    font-size: 14px;
                    margin-bottom: 0px;
                    li{ 
                        list-style: none;
                        padding: 0px;
                        margin-left: 20px;
                        cursor: pointer;
                        &:first-of-Type{
                            margin-left: 0px;
                        }
                        &.active{ border-bottom: 2px solid $quirion-dark-blue; padding-bottom: 1px;}
                    }
                    
                    @media screen and (max-width: $breakPointS) {
                        display: inline-block;
                        width: 100%;
                        li{
                            width: 40%;
                            margin: 0px;
                            display: inline-block;
                            margin-bottom: 10px;
                        }
                        li:nth-of-type(2),
                        li:nth-of-type(4){
                            margin-left: 10%;
                        }
                    }
                    
                }
                .detail{display: none;}
                .title{
                    //background-color: $quirion-dark-blue;
                    //color: white;
                    font-size: 21px;
                    font-family: 'Museo';
                    padding: 10px 0px;
                }
                
                .transactions{
                    .table{
                        >div:nth-of-type(1){
                            font-weight: bold;
                            font-size: 12px;
                        }
                        >div{
                            >div{
                                padding: 5px 3px;
                                border-bottom: 1px solid grey;  
                                text-align:right;
                            }
                            >div:nth-of-type(1){
                                text-align:left;
                            }
                        }
                    }
                }
                
                .cashInOutPlans{
                    
                    .details.table{
                        display: table!important;
                        width: 100%;
                        >div{
                            >div{
                                width: 20%;
                                padding-top: 3px;
                            }
                            >div:nth-of-type(1){
                                font-weight: bold;
                                font-size: 11px;
                            }
                            >div:nth-of-type(2){
                                width: 60%
                            }
                            >div:nth-of-type(3){
                                text-align: right;
                                span{
                                    background-image: url('/images/icons/arrow_grey.svg');
                                    background-repeat: no-repeat;
                                    background-position: right center;
                                    background-size: 11px 11px;
                                    padding-right: 15px;
                                    margin-left: 20px;
                                }                                
                            }
                        }
                    }
                    
                    .table.list_elements{
                        border-spacing: 0px;
                        margin-top: 5px;
                        thead{ 
                            tr{
                                >td{
                                    font-weight: bold;
                                    padding-bottom: 5px;
                                    border-top: 0px;
                                    padding-top: 0px;
                                    text-align: right;
                                }
                                >td:first-of-Type{
                                    text-align: left;
                                }
                            }
                        }
                        width: 100%;                        
                        tbody{
                            tr{
                                >td{
                                    width: 20%;
                                    border-top: 1px solid #c9c4cf;
                                    margin-top: 4px;
                                    padding-top: 4px;
                                    text-align: right;
                                }
                                >td:nth-of-type(1){
                                    width: 40%;
                                }
                                >td:nth-of-type(2){
                                    color: $color-robo-blue;
                                    font-weight: bold;
                                }
                                >td:first-of-Type{
                                    text-align: left;
                                }
                                
                                &:last-of-Type{
                                    >td{
                                        border-bottom: 1px solid #c9c4cf;
                                        padding-bottom: 4px;
                                    }
                                }
                                
                                &.details{
                                    >td{
                                        border-top: 0px;
                                    }
                                }
                            }
                        }
                        
                        .details{ padding-bottom: 10px;}

                        .row{
                            >td{
                                padding: 7px 0px;
                            }
                            >td:nth-of-type(4){
                                background-image: url('/images/icons/arrow_down_grey.svg');
                                background-repeat: no-repeat;
                                background-position: right center;
                                background-size: 14px 14px;
                                padding-right: 24px;
                            }
                            
                            &.show{
                                >td:nth-of-type(4){
                                    background-image: url('/images/icons/arrow_up_grey.svg');
                                }
                            }
                        }
                    }
                    
                    .button_holder{
                        &.list{
                            margin-top: 15px;   
                        }
                        &.noElements{
                            margin-top: 40px;
                        }
                    }
                    
                    .button{
                        min-width: inherit;
                        width: 170px;                        
                        font-size: 12px;
                        height: inherit;
                        background-color: transparent;
                        color: $text-color-robo;
                        padding: 10px 35px 10px 14px;
                        margin-right: 0px;
                        margin-bottom: 8px;
                        border: 1px solid $text-color-robo;
                        text-align: left;
                        background-image: url(/images/icons/arrow.svg);
                        background-repeat: no-repeat;
                        background-position: right 10px center;
                        background-size: 13px 13px;
                        &.important{
                            background-image: url(/images/icons/cross_white.svg);
                            width: 130px;
                            background-color: $color-robo-blue;
                            border: 1px solid $color-robo-blue;
                            color: white;
                            margin-right: 10px;
                        }
                    }
                }

                .detailshow{
                    .table{
                        width: 100%;
                        .finish,
                        .head,
                        .element{
                            >div{
                                text-align: right;
                                padding: 5px 3px;
                                border-top: 1px solid grey;
                            }
                            >div:nth-of-type(1),
                            >div:nth-of-type(2){text-align: left;}
                            >div:nth-of-type(3),
                            >div:nth-of-type(4),
                            >div:nth-of-type(5),
                            >div:nth-of-type(6),
                            >div:nth-of-type(7),
                            >div:nth-of-type(8)
                            {white-space: nowrap;}

                            span.smaller{font-size: 10px;}
                        }
                        
                        .allin{
                            color: white;
                            background-color: $quirion-dark-blue;
                            text-align: right;
                            >div:nth-of-type(1){
                                text-align: left;
                                font-family: 'Museo';
                                font-size: 16px;
                                padding: 4px 7px;
                            }
                        }
                        
                        .head{
                            >div{ border-top:100px;
                            
                                &.icon_info{
                                    &::after{
                                        cursor: pointer;
                                        content: '';
                                        background : url(/images/icons/info_grey.svg) no-repeat;
                                        margin-left: 5px;
                                        padding-right: 15px;
                                        background-size: 13px;
                                    }
                                }
                            }
                        }
                    }
                    .finish,
                    .head{
                        font-weight: bold;
                        white-space: nowrap;
                    }
                    .finish.liquiditaet >div{ padding-bottom: 30px!important;}
                    .group{
                        span{
                            display: inline-block;
                            color: white;
                            padding: 4px 7px;
                            font-size: 17px;
                            font-family: 'Museo';
                            margin-bottom: 10px;
                            margin-top: 20px;
                        }
                        
                        &.aktien span{
                            background-color: #2a1340;
                        }
                        &.anleihen span{
                            background-color: #964db3;
                        }
                        &.rohstoffe span{
                            background-color: #FF9100;
                        }
                        &.liquiditaet span{
                            background-color: #56cb94;
                        }
                    }
                }
            }

            &.waitmoney{
                ul.nav,
                .detail,
                .detailshow{ display: none;}

                .details_container{
                    >div:nth-of-type(2){
                        >div:nth-of-type(1){
                            >div:nth-of-type(2){
                                display: none!important;
                            }
                        }
                    }
                }
            }

        }
    }
}
