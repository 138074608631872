@font-face {
	font-family: 'Museo';
	font-weight: normal;
	src: url("/fonts/museo-300.woff2") format("woff2"), url("/fonts/museo-300.woff") format("woff");
}

@font-face {
	font-family: 'Museo';
	font-weight: bold;
	src: url("/fonts/museo-700.woff2") format("woff2"), url("/fonts/museo-700.woff") format("woff");
}

@font-face {
	font-family: 'Roboto';
	font-weight: 100;
	src: url("/fonts/roboto-light.woff2") format("woff2"), url("/fonts/roboto-light.woff") format("woff");
}

@font-face {
	font-family: 'Roboto';
	font-weight: normal;
	src: url("/fonts/roboto-regular.woff2") format("woff2"), url("/fonts/roboto-regular.woff") format("woff");
}

@font-face {
	font-family: 'Roboto';
	font-weight: bold;
	src: url("/fonts/roboto-bold.woff2") format("woff2"), url("/fonts/roboto-bold.woff") format("woff");
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: normal;
	src: url("/fonts/roboto-italic.woff2") format("woff2"), url("/fonts/roboto-italic.woff") format("woff");
}

@font-face {
	font-family: 'Roboto';
	font-weight: lighter;
	src: url("/fonts/roboto-light.woff2") format("woff2"), url("/fonts/roboto-light.woff") format("woff");
}

/*
@font-face {
	font-family: 'Roboto';
	font-weight: 900;
	src: url("/fonts/roboto-optimized.woff2") format("woff2"), url("/fonts/roboto-optimized.woff") format("woff");
}
*/

body{
    background: url(/images/bg.jpg) no-repeat center center fixed;
    @media screen and (max-width: $smallRoboMaxWidth) {
        background-image: url(/images/bg_m.jpg);
    }
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    font-family: 'Roboto',Arial,sans-serif;
    
    &.showMenu{
        @media screen and (max-width: $mainMenuResponsiveWidth) {
            overflow: hidden;
            #content{ display: none;}    
        }
    }
}

h1,h2,h3,h4,h5,h6 {
	font-family: 'Museo',Arial,sans-serif;
	font-weight: 100;
}

input[type=text]:disabled { 
    background: white; 
} 

.tracking_frame,
#tracking_frame{ width: 1px; height: 1px;border: 0px; visibility: hidden;}

#page{
    background-image: url(/images/menu_bg_bg.gif);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 85px;
    
    @media screen and (max-width: $breakPointS) {
        background-size: 100% 65px;    
    }
}

.table{
    display: table!important;
    
    >div{
        display: table-row!important;
        //&.table-group{display: table-row-group!important;}
        >div{display: table-cell!important;}
    }
}

.table-scrollable{
    width: 100%;
    overflow-y: auto;
    margin: 0 0 1em;
}

.table-scrollable::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
  }
  
  .table-scrollable::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid #fff;
    background-color: rgba(0, 0, 0, .3);
  }

.table > div.dontDisplayContent,
.dontDisplayContent{display: none!important;}

.link{
    color: $color-robo-blue;
    text-decoration: underline;
}

a,
.link,
.button,
button{
    cursor: pointer;

    &.shy{
        text-decoration: none;
        color: inherit;
    }
}

.center_atBreakpointS{
    @media screen and (max-width: $breakPointS) {
        text-align: center!important;
    }
}

#loading{
    position: fixed;
    z-index: 1000;
    width:100%;
    height:100%;
    top: 0px;
    left: 0px;

    >div {
        position: absolute;
        top: 50%;
        left: 50%;


        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        >div:nth-of-type(1){
            height: 130px;
            width: 130px;
            background-image: url(/images/logo_simpel.png);
            background-size: contain;
            background-repeat: no-repeat;
            margin: auto;
        }

        >div:nth-of-type(2){
            margin-top: 30px;
            height: 2px;
            width: 250px;
            
            background: linear-gradient(88deg, #44a1ee, #bf358e);
            background-size: 400% 400%;
            
            -webkit-animation: AnimationName 4s ease infinite;
            -moz-animation: AnimationName 4s ease infinite;
            -o-animation: AnimationName 4s ease infinite;
            animation: AnimationName 4s ease infinite;
            
            @-webkit-keyframes AnimationName {
                0%{background-position:0% 56%}
                50%{background-position:100% 45%}
                100%{background-position:0% 56%}
            }
            @-moz-keyframes AnimationName {
                0%{background-position:0% 56%}
                50%{background-position:100% 45%}
                100%{background-position:0% 56%}
            }
            @-o-keyframes AnimationName {
                0%{background-position:0% 56%}
                50%{background-position:100% 45%}
                100%{background-position:0% 56%}
            }
            @keyframes AnimationName { 
                0%{background-position:0% 56%}
                50%{background-position:100% 45%}
                100%{background-position:0% 56%}
            }     
        }
    }

    &:before {
        content: " ";
        position: absolute;
        top: 0; 
        left: 0;
        width: 100%; 
        height: 100%;  
        opacity: .9; 
        z-index: -1;
        background-color: #2a1340;
        //background: url(https://31.media.tumblr.com/8ec07e49f33088c2e32c158ca4262eb2/tumblr_n5wav6Tz4R1st5lhmo1_1280.jpg);
      }    

}

.checkbox_container{
    display: flex;
    padding-top: 3px;
    .radio,
    .checkbox{
        cursor: pointer;
        display: inline-block;
        min-height: 19px;
        background-image: url(/images/checkbox_off.gif);
        background-repeat: no-repeat;
        background-position: left top;
        &.selected{
            background-image: url(/images/checkbox_on.gif)!important;
            }
        
        label{
            padding-right: 20px;
            padding-left: 31px;
            background: none;
            cursor: pointer;
        }
    }
}

.noCursor{
    cursor: auto!important;
}

.form.dialog,
form{
    .form-group {
        position:relative;
      
        & + .form-group {
            margin-top: 30px;
        }
    }
    
    .form-label {
        position: absolute;
        left: 0;
        top: 14px;
        color: #504763;
        z-index: 10;
        transition: transform 150ms ease-out, font-size 150ms ease-out;
        left:65px;
        font-size: 24px;
    }
    
    .focused .form-label {
        transform: translateY(-34px) translateX(-65px);
        font-size: .95em;
    }
    
    .form-input {
        position: relative;
        padding: 12px 0px 5px 0;
        width: 100%;
        outline: 0;
        border: 0;
        box-shadow: 0 1px 0 0 #e5e5e5;
        transition: box-shadow 150ms ease-out;
    }    
}

.displayNone{display: none;}

.form.dialog{
    .form-group{padding-bottom: 15px;}
    .form-label {font-size: 16px;left:15px;top: 10px;}
    .focused .form-label {
        transform: translateY(-28px) translateX(-15px);
        font-size: .95em;
    }
    .errorLegi{
        label{
            background-image: url(/images/status_attention.svg);
            background-repeat: no-repeat;
            background-position: right;
            padding-right: 15px;
            background-size: 12px 12px;
        }
    }
    .error{
        .form-group{padding-bottom: 0px;}
        label{
            color: red;
        }
        select,
        input{
            border-bottom: 1px solid red;
            color: red;
        }
        .showIfError{display: block; color:red; padding-bottom: 15px; font-size: 12px; padding-left: 15px;}
    }
    .buttonHolder{ margin-top: 0px!important;}
    .legitimation{
        text-align: center;
        .button_legitimation_post{
            border: 0px;
            height: auto;
            width: auto;
            padding: 0px;
            @media screen and (max-width: $breakPointS) {
                width: 100%;
            }
        }    
    }
}

.hint_attention{
    padding: inherit;
    display: inline-block;
    height: 0px;
    width: 0px;
    margin-top: -7px;
    margin-left: -1px;
    background-image: url(/images/status_attention.svg);
    background-repeat: no-repeat;
    margin-right: 4px;
}

#logo_ani{
    display: inherit!important;
    /*
    position: absolute;
    z-index: 111;
    width: 300px;
    margin-left: calc(50% - 50px);
    top:1vh;
     */
    width: 100%;
    @media screen and (min-width: 1600px) {
        width: 1400px;
    }
    @media screen and (min-width: 1700px) {
        width: 1500px;
    }
    @media screen and (min-width: 1800px) {
        width: 1600px;
    }
    @media screen and (min-width: 1900px) {
        width: 1700px;
    }

    margin: auto auto;
    padding-left: 0px;
    padding-top: 3px;
    svg{width: 200px; margin-left: 15px;}
    
    @media screen and (max-width: $breakPointS) {
        svg{width: 150px;}
    }
}

.partner #logo_ani{
    bottom:1vh;
    top:auto;
    right: 2vh;
    bottom: 1vh;
    margin-left: auto;
    width: 150px;
    .cls-11 .cls-8{ fill:#2a1340!important;} // color letters // #2a1340
    &:after {
      content: "powered by";
      color: black;
      font-size: 12px;
      padding-left: 9px;
      display: block;
      margin-top: -9px;
    }
}

#logo{
    /*
    position: absolute;
    z-index: 111;
    text-align: center;
    top:1vh;
    width: 200px;
    margin-left: calc(50% - 100px);
    */
    display: block;
    position: absolute;
    height: 85px;
    width: 100%;
    background-image: url(/images/bg_menu.jpg);
    z-index: 0;
    background-repeat: no-repeat;
    background-position: center;
    top:0px;
    
    @media screen and (max-width: $breakPointS) {
        height: 65px;
    }
}

.noborder{border: 0px!important;}
.hidden{display: none;}

.errHint{ color: red;}

textarea:focus, input:focus{
    outline: none;
}

body.hideMainBar{
    #page{
        background: none;
    }
    #logo{
        display: none;
    }
    &.robo{
    #page{
        background: inherit;
    }
    #logo{
        display: inherit;
    }
    }
}

.radio_group_container{ 
    display: flex;
    .radio_container{
        margin-right: 28px;
    }    
}

.radio_container{
    display: block;
    position: relative;
    padding-left: 32px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        left: 0px;
        top:0px;
        z-index: 111;
        
    }

    &:hover input ~ .checkmark {
        //background-color: yellow;
    } 
    input:checked ~ .checkmark {
        background-color: #2196F3;
    }

    input:checked ~ .checkmark:after {
        display: block;
    }
    .checkmark:after {
        top: 0px;
        left: 0px;
        width: 15px;
        height: 15px;
        //border-radius: 50%;
        background: #2196F3;
        border: 2px solid white;
    }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: white;
  //border-radius: 50%;
  border:1px solid #2196F3;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.loading{
    background-color: grey!important;
    border-color: grey!important;
    cursor: auto!important;
    .spinner{
        background-image: url(/images/spinner_white.svg);
        width: 26px;
        height: 26px;
        background-size: 26px 26px;
        background-repeat: no-repeat;
        display: inline-block;
        padding-right: 8px;
        position: absolute;
        margin-left: -45px;
        margin-top: -1px;
    } 
}

.dialog .loading .spinner{
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    margin-left: -30px;
    margin-top: -2px;
}

/*Tooltip text*/

.icon_info .tooltip{
    width: 250px;
}

.tooltip {
    position: relative;
    font-family: 'Roboto',Arial,sans-serif;
  }
  
  .tooltip .tooltip-text {
    font-size: 1em;
    line-height: 1.5em;
    visibility: hidden;
    max-width: 300px;
    min-width: 230px;
    background-color: #fff;
    color: $text-color-robo ;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: .3s;
    transform: translate3d(0px, 20px, 0px);
  }
  
  .tooltip.dark .tooltip-text {
    background-color: $text-color-robo;
    color:  #fff;
    a{ color:  #fff;}
  }

  .tooltip .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 0px;
  }
  
  .tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
  
  .details_container .tooltip .tooltip-text {
    margin-left: -140px;
    max-width: 250px;
  }



.hint--generic,
.hint--danger {
    font-weight: bold;
}

.hint--generic {
    // color: $color-robo-blue;
}

.hint--danger {
    color: $quirion-purple;
}
