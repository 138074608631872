#setup{
    font-family: 'museo';

    .quirionidheader{
        color: $quirion-dark-blue;
        font-weight: bold;
        .quirionid{
            background-color: #73667f;
            color: white;
            display: inline-block;
            padding: 5px 10px;
            margin-top: 5px;
        }
    }

    .beraterInfos{
        margin-top: 20px;
        margin-bottom: 20px;
        .table{
            margin-bottom: 20px;
            margin-top: 20px;
        }
        .head{
            color: $color-robo-blue;
        }

        &.premium{
            display: inline-flex;
            a{
                color: #2a1340;
                font-weight: bold;
            }
            .table{
                margin-bottom: 0px;
                >div{
                    >div:nth-of-type(2){
                        font-weight: bold;
                    }                    
                }
            }
            >div{
                width: calc(50% - 10px);
                flex-grow: 1;
            }
            >div:nth-of-type(1){
                margin-right: 20px;
                .head{
                    font-size: 22px;
                }
                .berater{
                    display: table;
                    margin-top: 10px;
                    padding-right: 20px;
                    .image{
                        margin-right: 10px;
                        img{
                            width: 72px;
                        }
                    }
                    .name{
                        display: table-cell;
                        text-align: left;
                        vertical-align: middle;                        
                    }
                }
            }
            >div:nth-of-type(2){
                border: 1px solid $color-robo-blue;
                padding: 7px 10px;
                height: fit-content;
            }

            @media screen and (max-width: $breakPointS) {
                flex-direction: column;
                >div:nth-of-type(1),
                >div:nth-of-type(2){
                    width: 100%;
                }
                >div:nth-of-type(2){ display: none;}
            }

        }
    }

    .button {
        min-width: inherit;
        font-size: 12px;
        height: inherit;
        background-color: transparent;
        color: #2a1340;
        padding: 10px 35px 10px 14px;
        margin-right: 0px;
        margin-bottom: 8px;
        border: 1px solid #2a1340;
        text-align: left;
        background-image: url(/images/icons/arrow.svg);
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 13px 13px;
        display: block;
    }
    
}

