.messages{
    width: 100%;
    padding: 0px;
    margin: 0px;
    color: white;
    font-weight: lighter;
    
    .message{
        border: 0px;
        border-top: 1px solid transparent;
        background-color: #56cb94;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
        display: flex;
        
        &.attention{
            background-color: #ff9100;
        }
        
        
        .info{
            flex-grow: 4;
            padding-left: 10px;
            padding-right: 5px;
            }
        .controls{ 
            flex-grow: 1;
            margin-top: -6px;
            padding-left: 5px;
            padding-right: 10px;
            text-align: right;
            .button{
                width: 138px;
                height: 24px;
                border: 1px solid white;
                display: inline-block;
                padding-top: 5px;
                text-align: center;
            }
        }
    }
    .splitter{
        height: 1px;
    }
}

.robo .messages{ display: none;}
