$text-color-robo: #2a1340;
$text-color-robo-active: white;
$color-robo-blue: #2daafa;
$quirion-dark-blue: #2a1340;
$quirion-light-grey: #e7e5ea;
$quirion-dark-grey: #bab4c1;
$quirion-light-blue: #534565;
$quirion-purple: #d01a91;
$details-grey: grey;

$quirion-coplor-step1: #0096f3;
$quirion-coplor-step2: #2e74d7;
$quirion-coplor-step3: #5d54bb;
$quirion-coplor-step4: #8e37a1;
$quirion-coplor-step5: #ca1986;
