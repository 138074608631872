.reset_password,
.login{ 

    position: absolute;
    margin: auto;
    width: 500px;
    height: 600px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;

    @media screen and (max-width: 500px) {
        position: inherit;
        margin: 0px;
        width: auto;
        height: auto;
        text-align: center;
    }
    .form{
        max-width: 660px;
        margin: auto;
        padding-bottom: 0px;

        .form-label{
            left: 83px;
            @media screen and (max-width: 700px) {
                left: 20%
            }    
        }

        .go_on{

            border: 1px solid #2daafa;
            padding: 17px 35px 17px 35px;
            margin: 10px;
            font-size: 20px;
            min-width: 170px;
            &.large{
                min-width: 290px;
            }
            display: inline-block;
            cursor: pointer;
            background-color: #2daafa;
            color: white;
            font-weight: lighter;
            &.light{
                background-color: white;
                color: #2daafa;
            }
        }

    }
    h1{
        margin: 0px;
        margin-top: 20px;
        margin-bottom: 50px;
    }
    
    &.logout h1{font-size: 2.0vh;}

    a{ text-decoration: underline; color: #2a1340;}

    .logo img{width: 90px; margin-top: 30px;}
    input{ 
        width:400px;
        height: 57px;
        background-color: #f0eef1;
        border: 0px;
        border-bottom: 1px solid #504763;
        padding-left: 22px;
        font-size: 24px;
        font-weight: lighter;
        margin-bottom: 10px;
        color: #504763;
        padding-left: 60px;
        background-size: 25px 25px!important;
        background-position: 20px;
        background-repeat: no-repeat;
        padding-top: 0px;
        padding-bottom: 0px;

        @media screen and (max-width: 700px) {
            width: 80%;        
        }
    }

    .email:-webkit-autofill,
    .password:-webkit-autofill{
        padding-top: 1px; // its for detecting if the field was autofilled
    }

    .username,
    .email{
        background-image: url(/images/icons/message_black.svg);
    }
    .password{
        background-image: url(/images/icons/padlock_black.svg);
    }

    .button{
        width:auto;
        background-color: #68a8ee;
        min-width: 200px;
        height: 49px;
        color: white;
        border: 0px;
        font-weight: lighter;
        padding: 0px 20px;
        font-size: 19px;
    }        
}
    
.reset_password{
    .form{
        input[type=submit]{
            margin-bottom: 20px;
            cursor: pointer;
        }
    }
}    

