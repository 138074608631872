
body.robo{
           
    .icon_info{
        &::after{
            cursor: pointer;
            content: '';
            background : url(/images/icons/info_grey.svg) no-repeat;
            margin-left: 5px;
            padding-right: 15px;
            background-size: 15px;
        }
    }

    .nextBtn{
        cursor: pointer;
    }
    //overflow: hidden;

    &:not(.new_product){
        #logo{
            z-index: 105;
            background-image: none;
            width: 60%;
            margin-left: 20%;
            margin-right: 20%;
            #logo_ani{
                width: 96px;
                overflow: hidden;
                padding: 0px;
                padding-top: 15px;
                svg{
                    width: 250px;
                    margin-left: 0px;
                }
            }
        }
    }

    div.robo{

        .overlay{
            z-index: 100!important;
            background-color: #2a1340!important;
            opacity: 0.8!important;
            height: 100vh!important;
            width: 80vw!important;
            display: flex!important;
            flex-direction: column!important;
            align-items: center!important;
            position: fixed!important;
            top: 0!important;
            padding-top: 9rem!important;
            padding-left: 10vw;
            padding-right: 10vw;
            overflow: auto;
            @media screen and (max-width:684px) {
                padding-top:7rem!important
            }            
        }

        .q_param_wphg_knowledge_self{
            .grid.multiple{
                grid-template-columns:none!important;
                display: block!important;
                .elem1{  margin-top: 50px; display: block; text-align: center; }
                .row{ 
                    margin-top: 30px;
                    display: flex;
                    .elem2{ flex-grow: 1; text-align: right; padding-right: 2vw; }
                    .elem3{ flex-grow: 1; text-align: left; padding-left: 2vw; }
                }
            }
        }

        .question{
            .q_param_wphg_drawdown_capacity{
                fieldset{
                    >div{
                        min-height: 50px;
                    }
                }
            }

            &.q_param_wphg_holding_period{
                form.number{
                    >div:nth-of-type(1) >span{
                        @media screen and (max-width: 780px) {
                            text-align: center;
                        }
                        >span{
                            display: block;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }

        .sliderElement{

            .details_opener{
                font-size: 1rem!important;
                max-width: 40rem;
                margin:  50px auto 0.5rem auto!important ;
                @media screen and (max-width: 700px) {
                    padding:  0rem 20px!important;
                }

                >span{
                    cursor: pointer;
                    >span{
                        //cursor: auto;
                    }
                }

            }

            .type_choice{
                padding: 0px 20px;
                h3{
                    width: auto;
                }

                .grid{
                    font-size:1.4rem;
                    text-align: left;
                    display: grid;
                    grid-template-columns: minmax(auto, 1fr) minmax(auto, 1fr) 4fr minmax(auto, 1fr) minmax(auto, 1fr);
                    align-items:center;
                    justify-items: center;
                    grid-auto-flow:column;
                    grid-row-gap: .8em;
                    margin-top: 0;                    
                }

                .details_opener{
                    margin:  0.5rem auto!important ;
                    @media screen and (max-width: 700px) {
                        padding:  0rem 0px!important;
                    }
                }
                .choice{
                    .details_opener{
                        margin:  50px auto 0.5rem auto!important ;
                    }
                }
            }
        }

        .showIfError{
            display: none;
            &:before{
                content: url(/images/active_error_arrow.png);
                position: absolute;
                margin-top: -24px;
                margin-left: -10px;
                left: 50%;
            }
            color: white!important;
            background-color: #d01a91;
            margin: 6px 0px 0px 0px!important;
            padding: 10px!important;
            padding-left: 15px!important;
            padding-right: 15px!important;
            font-size: 15px!important;
            position: inherit;
        }

        .error .showIfError{ display: block;}

        .counter{
            display: none;
            position: absolute;
            text-align: center;
            width: 100%;
            margin-top: 90vh;
            color:  $text-color-robo;
            font-size: 12px;

            @media screen and (max-width: $smallRoboMaxWidth) {
                position:inherit;
                margin-top: 11vh;
            }
        }

        .next{
            //position: absolute;
            text-align: center;
            width: 100%;
            margin-top: 11vh;
            min-height: 55px;

            span{
                    border: 1px solid $text-color-robo;
                    display: inline-block;
                    padding: 5px;
                    margin: 10px;
                    min-width: 120px;
                    font-size: 20px;
                    cursor: pointer;
                    color: $text-color-robo;
                    a{
                        color: $text-color-robo;
                        text-decoration: none;
                    }
            }
            @media screen and (max-width: $smallRoboMaxWidth) {
                position:inherit;
                margin-top: 11vh;
            }
        }

        .contentHolder{
            margin: auto;
            width: 60vw;
            @media screen and (max-width: $smallRoboMaxWidth) {
                width: 80vw;
                
            }
            input{
                display: block;
                width: 100%;
                margin-bottom: 1vw;
                background-color: transparent;
            }
        }

        .closer_main{
            position: absolute;
            z-index: 115;
            right: 0px;
            top: 0px;
            display: inline-block;
            width: 37px;
            height: 37px;
            background-image: url(/images/icons/cross_rotated_grey.svg);
            margin-top: 2.0vh;
            margin-right: 2.5vh;
        }

        .slick-slide{
            border: 0px;
            div{
              outline: 0!important;
              -moz-outline-style: none!important;
            }
        }

        #sliderElement_first{
            z-index: 2
        }

        #sliderElement_second{
            z-index: 1;
            opacity: 0;
        }

        .sliderElement{

            min-width: 100%;
            position: absolute;
            overflow:hidden;
            background: url(/images/bg.jpg) no-repeat center center fixed;
            @media screen and (max-width: $smallRoboMaxWidth) {
                background-image: url(/images/bg_m.jpg);
                overflow:inherit;
            }
            -webkit-background-size:  100% 100%;
            -moz-background-size: 100% 100%;
            -o-background-size: 100% 100%;
            background-size: 100% 100%;
            position: absolute;
            /*min-height: 100%;*/
            width: 100%;
            top:0;
            left: 0;
            z-index: 104;

            .buttonHolder{
                display: inline-flex;
                flex-wrap: wrap;
                justify-content: center;
                div{
                    border: 1px solid $text-color-robo;
                    margin: 10px;
                    font-size: 19px;
                    a{
                        padding: 12px 5px 13px 5px;
                        min-width: 250px;
                        display: inline-block;
                        cursor: pointer;
                        color: $text-color-robo;
                    }

                    &.finish{
                        background-color: #2daafa;
                        color: white;
                        border: 0px;
                        min-height: auto;
                        span{
                            border: 0px;
                            color: white;
                        }

                    }
                }

                @media screen and (max-width: $smallRoboMaxWidth) {
                    flex-direction: column;
                }
            
                &.unimportant{
                    margin-top: 0px;
                    display: flex;
                    flex-direction: column;
                    div{
                        border: 0px;
                        a{
                            font-size: 17px;                    
                        }
                    }
                }
            }

            legend{
                color: $color-robo-blue;
                padding: 0px 20px;
                font-size: 22px;
                text-transform: uppercase;
                font-weight: lighter;
            }

            .button{
                border: 1px solid #2a1340;
                display: inline-block;
                padding: 5px;
                margin: 10px;
                min-width: 200px;
                font-size: 20px;
                cursor: pointer;
                color: #2a1340;
            }

            .checkbox{
                cursor: pointer;
                display: inline-block;
                min-height: 19px;
                background-image: url(/images/checkbox_off.gif);
                background-repeat: no-repeat;
                background-position: left top;
                &.selected{
                    background-image: url(/images/checkbox_on.gif)!important;
                }

                label{
                    padding-right: 20px;
                    padding-left: 25px;
                    background: none;
                    cursor: pointer;
                }
            }

            .type_standard{
                .text{
                    margin-bottom: 5vh;
                    font-size: 20px;
                }
                .question{
                    margin-top: 14vh!important;
                }

                @media screen and (max-width: $smallRoboMaxWidth) {
                    margin-top:-13vh;
                    .question{
                        margin-top: 1vh!important;
                    }
                }
            }

            .type_promise{

                h3{ margin-top: 20vh!important;}

                .question{ //margin-top: 35vh!important;
                    }

                //.next{ margin-top: 84vh;}

                .buttonHolder{

                    display: inline-block;
                    width: 520px;
                    height: 260px;
                    overflow-y: scroll;
                    border: 0px solid black;
                    font-size: 18px;
                    -webkit-mask-image: linear-gradient(to top, transparent 0%, black 15%);

                    div{ border: 0px; padding: 0px; margin: 0px;}

                    .promise{
                        text-align: left;

                        .text{
                            display: none;
                            font-family: 'Roboto',Arial,sans-serif;
                            font-weight: lighter;
                            }

                        &.open .text{display: block; line-height: 25px; padding-top: 5px; margin-right: 20px; padding-bottom: 10px;}
                        .opener{
                            background-image: url(/images/arrow_right_small.png);
                            font-family: 'Roboto',Arial,sans-serif;
                            border-bottom: 1px solid #d0c9d6;
                            background-repeat: no-repeat;
                            background-position: right center;
                            font-weight: bold;
                            margin-right: 20px;
                            min-height: 25px;
                            padding-top: 5px;
                        }
                        &.open .opener{background-image: url(/images/arrow_down_small.png);}
                    }
                }

                .promise_info{
                    width: 520px;
                    display: inline-block;
                    padding-top: 20px;
                    text-align: left;
                    a{color: #2a1340;}
                }

                .promise.checkbox{
                    width: 520px;
                    display: inline-block;
                    margin-top: 20px;
                    text-align: left;
                    label{
                        padding-left: 35px;
                        display: block;
                        a{color: #2a1340;}
                    }
                }
            }

            .type_selection{

                .subtext{
                    font-size: 14px;
                    ul{
                        padding-left: 40px;
                        li{
                            &:before{
                                content: "✓";
                                position: absolute;
                                margin-left: -20px;
                            }
                        }
                        list-style: none;
                    }
                }

                .question{
                    //margin-top: 38vh!important;
                    .active{
                        background-color: transparent;
                        border-color: transparent;
                        .button{
                            background-color: $color-robo-blue;
                            border-color: $color-robo-blue;
                            color: white;
                        }
                    }
                }

                .icon{
                    border: 0px;
                    display: inline-block;
                    height: 47px;
                    width: 200px;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                .buttonHolder{
                    > div{
                        border: 0px;
                        width: 380px;
                        a{width: 200px;min-width: 200px;}

                        @media screen and (max-width: 410px) {
                            width: 100%;
                            margin: 0px;
                        }                        
                        
                    }
                    .button{padding: 0px; height: 37px; padding-top: 13px; margin-top: 25px;}
                }
                fieldset{
                    border: 1px solid $text-color-robo;
                    line-height: 25px;
                    padding: 18px 36px;
                    div{
                        border: 0px;
                        margin: 0px;
                        font-size: inherit;
                        ul{
                            padding-left: 20px;
                            min-height: 83px;
                            margin-top: 0px;
                            margin-bottom: 0px;
                            padding-top: 0px;
                            li{
                                text-align: left;
                            }
                        }
                    }
                    @media screen and (max-width: 410px) {
                        margin: 10px;
                    }
                }

                    fieldset{
                        border: 10px;
                    }

                &.param_account_type
                &.param_for_what{
                    fieldset{
                        border: 0px;
                    }
                }

                @media screen and (max-width: $smallRoboMaxWidth) {
                    margin-top:-13vh;
                    .question{
                        margin-top: 1vh!important;
                    }
                }
                
                @media screen and (max-width: 1200px)  and (min-width: $breakPointM) {
                    h3{margin-top: 20vh;}
                }
                @media screen and (max-width: $smallRoboMaxWidth)  and (min-width: $breakPointM) {
                    h3{margin-top: 3vh;}
                }
                
                
            }

            .type_risiko_praeferenz{

                @media screen and (min-width: $smallRoboMaxWidth) {
                    h3{
                        margin-top: 24vh!important;
                    }
                    //.question{ margin-top: 38vh!important;}
                }

                @media screen and (max-width: $smallRoboMaxWidth) {
                    margin-top:-13vh;
                    .question{
                        margin-top: 1vh!important;
                    }
                }
                
                .buttonHolder{
                    >div{ width: 295px;}

                    div{
                        font-size: 16px;
                        border: 0px;
                        fieldset{
                            border: 1px solid $text-color-robo;
                            padding: 0.35em 11px 0.625em;
                            .details{
                                margin: 0px;
                                min-height: 72px;
                                @media screen and (max-width: 700px) {
                                    min-height: 91px;
                                }                                
                            }
                        }
                        &.active{
                            background-color: inherit;
                            fieldset{ border: 4px solid $color-robo-blue; }
                        }

                        .rendite{
                            padding: 0px;
                            font-size: 28pt;
                            font-weight: lighter;
                            margin: 0px;
                            padding-bottom: 10px;
                        }
                        .rendite_label{ padding: 0px; margin: 0px;    margin-top: 15px;}
                        .maxPain{ padding: 0px; margin: 0px;}

                    }
                    
                    @media screen and (max-width: $smallRoboMaxWidth) {
                        flex-direction: row;
                    }
                    
                    .rendite,
                    .maxPain,
                    .rendite,
                    .rendite_label{
                        display: none;
                    }
                }
                .next{
                    //margin-top: 82vh;
                }

                .button{
                    background-color: #8e8e8e;
                    border-color: #8e8e8e;
                    color: white;
                    font-weight: lighter;
                    min-width: 161px;
                    height: 29px;
                    padding: 0px;
                    padding-top: 3px;
                }
                .active{
                    .button{
                        background-color: $color-robo-blue;
                        border-color: $color-robo-blue;
                    }
                }
            }

            .type_choice{

                .buttonHolder{
                    margin-top: 4vw;
                    > div{
                        a{width: 200px;min-width: 200px;}
                    }
                }

                .question{ margin-top: 40vh!important; }

                .iconHolder{
                    display: inline-flex;
                    .icon{
                        padding-top: 70px;
                        background-repeat: no-repeat;
                        background-position: center top;
                        width: 220px;
                        margin-bottom: 0px;
                        line-height: 23px;
                        >div{
                            height: 60px;
                            background-repeat: no-repeat;
                            background-position: center center;
                            margin-bottom: 2vh;
                        }
                    }

                    @media screen and (max-width: $smallRoboMaxWidth) {
                        padding: 0 3vw;
                        margin: 0;
                        list-style: none;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: flex;
                        -webkit-flex-flow: row wrap;
                        justify-content: space-around;
                    }
                }
                @media screen and (max-width: $smallRoboMaxWidth) {
                    margin-top:-13vh;
                    .buttonHolder{
                        margin-top: 9vw;
                        }
                    .question{
                        margin-top: 1vh!important;
                    }
                }
            }

            .numberHolder{
                display: inline-flex;
                flex-direction: column;
                text-align: center;                
                div{
                    input{border: 0px solid $text-color-robo;}
                    border: 0px solid $text-color-robo;
                    padding: 5px;
                    margin: 10px;
                    font-size: 3.5vw;
                    color: black;
                    a{
                        min-width: 250px;
                        display: inline-block;
                        cursor: pointer;
                        color: $text-color-robo;
                    }
                }

                @media screen and (max-width: $smallRoboMaxWidth) {
                    flex-direction: column;
                }

            .border_wrapper{
                border-bottom: 1px solid black;
                background-color: #f0eef1;
                margin: 0px auto;
                padding: 0px;
                height: 46px;
                padding-top: 9px;
                width: 310px;
                //display: flex;
                font-weight: $roboNumbersFontWeight;
                font-size: $roboNumbersFontSize;
                }
            }

            .slick-prev{display: none!important;}
            .slick-next{display: none!important;}
        }

        input[type="text"],
        input[type="password"]{
            border: 0px;
            border-bottom: 0px solid black;
            font-weight: $roboNumbersFontWeight;
            font-size: $roboNumbersFontSize;
            background: transparent;
            text-align: left;
            margin: 0;
            padding: 0;
            &.error{
                border-bottom: 3px solid red;
            }

            &.number{
                text-align: center;
                max-width: 14vw;
            }
        }

        input.label{ text-align: left; color:black; width: 30px; float: right; }

        .create{
            input[type="text"],
            input[type="password"]{
                width:600px;
                height: 57px;
                background-color: #f0eef1;
                border: 0px;
                border-bottom: 1px solid #504763;
                padding-left: 22px;
                font-size: 24px;
                font-weight: lighter;
                margin-bottom: 10px;
                color: #504763;
                padding-left: 60px;
                background-size: 25px 25px!important;
            }
            .email{
                background: url(/images/icons/message_black.svg) 20px center no-repeat #f0eef1!important;
            }
            .password{
                background: url(/images/icons/padlock_black.svg) 20px center no-repeat #f0eef1!important;
            }
            .errHint,
            .hint{ margin-top: 20px;}
            form{
                margin: auto auto;
                display: inline-block;
                max-width: 660px;
                @media screen and (max-width: $smallRoboMaxWidth) {
                    input{
                        width: calc(100% - 60px)!important;
                    }
                }
            }
        }

        .type_Jahre{
            input[type="text"]{ max-width: 85px; text-align: right;}
            input.label{ text-align: left; color:black; width: 94px; max-width: inherit; float: right; }
        }

        .type_Monate{
            input[type="text"]{ max-width: 100px; text-align: right;}
            input.label{ text-align: left; color:black; width: 125px; max-width: inherit; float: right; }
        }


        .control{
            position: absolute;
            top: 2.0vh;
            margin-left: 2vh;
            text-align: left;
            z-index: 300;
            a.back{
                display: inline-block;
                width: 19px;
                height: 35px;
                background-image: url(/images/icons/arrow_left_grey.svg);
                cursor: pointer;
                }
            img{ height: 4vh; }
        }

        .sliderElement{
            @media screen and (max-width: $smallRoboMaxWidth) {
                min-height:inherit;
            }
            text-align: center;
            padding-top: 30vh;
            
            h2,h3{
                width: 92vw;
                font-size: $roboHeadlineFontSize;
                padding: 0px 3vw;
            }
            h4{
                width: 92vw;
            }
            .question{
                width: 100vw;
                color: $text-color-robo;
            }

        }

        .sliderElement{ //vertical

            @media screen and (min-width: $smallRoboMaxWidth) {
                padding-top:inherit;
                h3{
                    //position: absolute;
                    margin-top: 32vh;
                }

                .question{
                    //position: absolute;
                    //margin-top: 45vh;
                    margin-top: 2vh;
                }

                .control{
                    margin-top: 2.5vh;
                    top:inherit;
                }
            }
        }

        .question{
            .active{
                background-color: #2daafa;
                border-color: #2daafa!important;
                a{color: $text-color-robo-active!important}
            }
        }
    }
    
    &.advise{
        overflow: inherit;
        

        .hint{
            color: #8e8e8e;
            margin-top: 20px;
            text-align: left;
        }

        .badDataHint{
            color: red;
            padding-top:20px;
        }

        .editable{
            
            .value{
                display: block;
            }
            .values{
                display: none;
                >div{
                    padding: 10px;  
                    border-bottom: 3px solid transparent;
                    &.active{ 
                        border-bottom: 3px solid $color-robo-blue!important;
                    }
                }
                
                .table{
                    background-color: white!important;
                    >div{
                        >div{
                            border-bottom: 3px solid transparent;
                            padding: 10px;
                        }
                        &.active{
                            >div{
                                border-bottom: 3px solid $color-robo-blue!important;
                            }
                        }
                    }
                }
            }
            
            &.select_value{
                
                .editable{
                    background-image:none!important;
                }
                
                .value{
                    display: none;
                }
                .values{
                    display: block;
                    
                }
            }
        }
        
        div.robo{
            
            .sliderElement{
                overflow: inherit;

                label.first{
                    padding-right: 0px;
                }

                label.second{
                    padding-left: 0px;
                    @media screen and (max-width: 650px) {
                        display: block;
                        padding-top: 10px;
                    }  
                }

                .product_hint{
                    padding-top: 10px;
                    font-size: 15px;
                    &.high{color: $quirion-purple;}
                    &.low{color: $color-robo-blue;}
                }

                .advise{
                    max-width: 630px;
                    display: block;
                    margin: 50px auto 0px auto;
                    font-size: 13px;
                    line-height: 17px;                                     
                    
                    .editable > .editable{
                        background-image: url(/images/edit_field.png);
                        background-repeat: no-repeat;
                        background-position: right 10px center;
                    }
                    
                    
                    input[type="text"]{
                        font-size: inherit;
                        width: 100%;
                    }
                    
                    h1{ 
                        position: inherit;
                        width: inherit;
                        height: 30px;
                        background-color: #2a1340;
                        color: white;
                        font-family: 'museo';
                        font-weight: normal;
                        padding: 23px 40px 7px;
                        margin: 0px;
                        text-align: left;
                    }
                    
                    ul{
                        width: inherit;
                        margin: auto;
                        text-align: left;
                        background-color: #f0eef1;
                        margin-bottom: 40px;
                        padding-top: 30px;
                        padding-bottom: 0px;
                        padding-right: 40px;
                        
                        &.options{
                            font-family: 'museo';
                           font-size: 16px;
                           .hint{
                               color: #8e8e8e
                           }
                        }                        
                        
                        li{
                            list-style: none;
                            margin-top: 0px;
                            padding-bottom: 30px;
                        }
                    }
                
                    .advise_container{
                        padding: 40px;
                        padding-top: 14px;
                        background-color: #f0eef1;
                        
                        .paket_container{
                            display:flex;
                            font-size:20px;
                            margin-top:20px;
                            >div{
                                flex-grow: 1;
                                border:1px solid grey;
                                padding:40px 0px;
                                &.selected{
                                    border:5px solid blue;
                                }
                            }
                        }
                        
                        .legende_historic_top,
                        .legende_future_top{ 
                            color: $quirion-dark-grey;
                            margin-top: 15px;}
                            
                        .legende_historic_bottom,
                        .legende_future_bottom{
                            background-color: #dfdce2;
                            height: 25px;
                            padding-top: 5px;
                            color: #564467;
                            font-size: 14px;
                            margin-top: 10px;
                            span{
                                padding-left: 30px;
                                &:before {
                                    content: "";
                                    display: inline-block;
                                    width: 13px;
                                    height: 13px;
                                    margin-right: 5px;
                                    background: Blue;
                                    margin-top: 2px;
                                    position: absolute;
                                    margin-left: -19px;
                                }
                                &.good{&:before {background: $color-robo-blue;}}
                                &.norm{&:before {background: $quirion-dark-blue;}}
                                &.bad{&:before {background: $quirion-purple;}}
                                &.value{&:before {background: $quirion-dark-blue;}}
                            }
                        }
                    }
                
                    .product_titile,
                    .condition_titile{
                        text-align: left;
                        font-family: 'Museo';
                        font-size: 30px;
                        color: $color-robo-blue;
                        line-height: 36px;
                        padding-top: 10px;
                    }

                    .product_titile{color: $quirion-dark-blue;   margin-top: 0px; }

                    .allokation{
                        font-family: 'Roboto',Arial,sans-serif;
                        display: flex;
                        margin-top: 20px;
                        font-size: 23px;
                        label{
                            font-family: inherit;
                            font-size: 12px;
                            margin-bottom: 5px;
                            margin-top: 13px;
                            color: $quirion-dark-blue;
                            &:nth-of-type(1){
                                margin-top: 20px;
                            }
                        }
                        >div{
                            width: 50%;
                            &:nth-of-type(1){
                                text-align: left;
                            }
                            &:nth-of-type(2){
                                display: flex;
                                flex-direction: column;
                            }
                        }
                    }

                    .product_selector{
                        display: flex;
                        align-items: center;
                        margin-top: 30px;
                        >div{
                            flex-grow: 1;
                            margin-right: 3px;

                            &:last-of-type{
                            margin-right: 0px;
                            }                            
                            
                            span{
                                background-color: $color-robo-blue;
                                display: inline-block;
                                width: 100%;
                                padding-top: 5px;
                                padding-bottom: 5px;
                                cursor: pointer;
                            }
                            color: white;
                            &.attention{
                                span{
                                    background-color: $color-robo-blue;
                                }
                            }
                            &.forbidden{
                                span{
                                    background-color: $details-grey;
                                    cursor: inherit;
                                }
                            }
                            &.active{
                                span{
                                    padding-top: 10px;
                                    padding-bottom: 10px;
                                    font-size: 17px;
                                }
                            }
                            &.best{
                                span{
                                    background-color: black;
                                }
                            }
                        }
                    }

                    .description_container{
                        margin-top: 18px;
                        border: 1px solid $quirion-dark-blue;
                        ul{
                            margin-bottom: 0px;
                            padding-bottom: 5px;
                            padding-top: 5px;
                            background-color: white;
                            flex-wrap: wrap;
                            display: flex;
                            li{
                                width: 44%;
                                padding-right: 6%;
                                list-style: inherit;
                                padding-bottom: 0px;
                                font-family: 'Museo';

                                @media screen and (max-width: 650px) {
                                    width: 100%;

                                }

                            }
                        }
                        
                        .head{ 
                            background-color: $quirion-dark-blue;
                            color: white;
                            height: 25px;
                            padding-top: 7px;
                            font-family: 'Museo';
                            font-size: 16px;
                        }
                    }
                    
                    .headline{
                        text-align: left;
                        font-weight: bold;
                        margin-top: 55px;
                        font-size: 13px;
                    }
                
                    .table{
                        background-color: #f0eef1;
                        padding-right: 13px;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        
                        &.assets{
                            width: 100%;
                            font-family: 'Museo';
                            >div{
                                font-size: 11px;
                                &:first-of-type{
                                    font-weight: bold;
                                }
                                >div{
                                    border-bottom: 1px solid $quirion-dark-grey;
                                    text-align: left;
                                    padding: 4px 0px;
                                    &:last-of-type{
                                        text-align: right;
                                    }
                                }
                            }
                        }
                        
                        &.costs{
                            width: 100%;
                            font-family: 'Museo';
                            padding-top: 10px;
                            >div{
                                font-size: 17px;
                                >div{
                                    border-top: 1px solid $quirion-dark-grey;
                                    text-align: right;
                                    padding: 8px 0px;
                                    &:nth-of-type(1){
                                        text-align: left;
                                    }
                                }
                                &:last-of-type{
                                    >div{
                                        font-weight: bold;
                                        border-top: 1px solid $quirion-dark-blue;
                                        border-bottom: 1px solid $quirion-dark-blue;
                                    }
                                }                                
                            }
                        }
                        
                        &.values{
                            padding-right: 5%;
                            margin-bottom: 40px;
                            width: 95%;
                            
                            .showIfError{
                                display: none;
                                position: absolute;
                                left: 0;
                                right: 0;
                                margin: 14px auto!important;
                                width: max-content;
                                width: -moz-fit-content;
                                max-width: 600px;
                            }
                        
                            .error{
                                color: $quirion-purple;
                                input{
                                    color: $quirion-purple;
                                }
                                &.active .showIfError{
                                    display: block;
                                    }
                                }
                                
                            >div{
                                >div{
                                    &:nth-of-type(1){
                                        width: 42%;
                                        text-align: right;
                                        font-size: 14px;
                                        font-weight: bold;
                                        vertical-align: middle;
                                    }
                                    &:nth-of-type(2){
                                        text-align: left;
                                        background-color: white;
                                        margin: 0px 5px 5px 5px;
                                        padding: 7px;
                                        display: block!important;
                                        font-size: 20px;
                                        color: $quirion-dark-grey;
                                        vertical-align: middle;
                                        
                                        &.double{
                                            display: flex!important;
                                            background-color: inherit;
                                            padding: 0px;
                                            margin: 0px 5px 5px 0px;
                                            >div{
                                                flex-grow: 1;
                                                background-color: white;
                                                width: 44%;
                                                padding: 7px;                                                
                                                &.left{
                                                    margin: 0px 5px 5px 5px!important;                                                
                                                }
                                                &.right{
                                                    margin: 0px 0px 5px 0px;
                                                }
                                                select{
                                                    border: 0px;
                                                    background-color: white;
                                                    width: 100%;
                                                    height: 27px;
                                                    margin-top: -5px;
                                                    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
                                                    -moz-appearance: none; 
                                                    -webkit-appearance: none; 
                                                    appearance: none;                                                    
                                                }
                                            }
                                        }
                                    }
                                }                            
                            }
                        }
                    }
                    .next{
                        position: inherit;
                        margin-top: 70px;
                    }
                }
            }
        }
    }

    .type_selection.param_advisor{
        fieldset{min-height: 150px;;}
    }    
}

