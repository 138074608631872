$smallRoboMaxWidth: 1024px;
$mainMenuResponsiveWidth: 1024px;
$roboNumbersFontSize:  33px;
$roboNumbersFontWeight: lighter;

$roboHeadlineFontSize: 30px;

$breakPointM: 960px;
$breakPointS: 600px;

/*

$border-radius: .25rem;

$padding-small: .25rem;
$padding-medium: .5rem;

$margin-small: .25rem;
$margin-medium: .5rem;

$font-size-large: 1.5rem;
$font-size-medium: 1.25rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
*/