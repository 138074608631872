.footer{
    text-align: center;
    ul{
        margin-top: 50px;
        font-size: 12px;
        display: inline-flex;
        li{
            list-style: none;
            padding-right: 20px;
        }
    }

    @media screen and (max-width: $mainMenuResponsiveWidth) {
        display: none;
    }
}

.hideMainBar{
    .footer{
        position: absolute;
        right: 0;
        bottom:0;
    }
}

.robo{
    // for QUIK-2647:
    // hide default page footer (as it was before...
    .footer{
        display: none!important;
    }

    // ... and instead show duplicate footer, inside of the question.
    // TODO: remove duplicate in the JS code, when restructuring robo layout css
    .sliderElement .footer {
        z-index: 3;
        display: block !important;

        ul {
            margin-top: 1em; // prevent click-blocking on details elements
        }
    }
}

.hideMainBar,
.robo{
    .footer{
        @media screen and (max-width: $mainMenuResponsiveWidth) {
            display: inherit;
        }
    }
}

