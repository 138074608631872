#registration ul.marketing.aktionen{
    padding: 0px;
    margin: 0px;

    @media screen and (max-width: $mainMenuResponsiveWidth) {
        //display: none;
    }
}


