#registration{
    
    max-width: 1024px;
    display: block;
    margin: auto auto;
        
    .showIfError{ display: none;}
    
    .hide{ visibility: hidden;}
    .displayNone{ display: none;}
    
    .right{
        text-align: right;
    }
    
    .check_valid{ color:green; padding-top:10px;}
    .check_loading{  padding-top:10px;}
    .check_error{ color:$quirion-purple; padding-top:10px;}
    
    &.add{
        &.step2{
            .buttonHolder{
                display: block!important;
                >div{
                    display: inline-block;
                }
            }
        }
    }

    &.step3{
        label{
            font-size: 12px;
            margin-bottom: 2px;
            display: block;
        }
        
        h2{
            margin-top: 20px;
            &.first{
                margin-top: 0px;
            }
        }
        
        .hint{ margin-top: 5px;}
    }
    
    &.step4{
        h2{
            margin-bottom: 20px;
        }

        .extraTopMargin{ margin-top:40px;}
        
        .checkbox_container.important{
            background-color: $quirion-dark-blue;
            color: white;
            padding: 10px;
        }
         a{color: $color-robo-blue;}
        
        .error .checkbox_container.important{
            border: 5px solid $quirion-purple;
            .checkbox{ color: white;}
        }
    }
    
    form.legitimation{ 
        text-align: center;
        .button_legitimation_post{
            width: inherit;
            padding: inherit;
            border: 0px;
            height: inherit;
            @media screen and (max-width: $breakPointS) {
                width: 100%;
            }
        }        
    }
    
    .control{
        font-family: arial;
        font-style: normal;
        color: grey;
        font-size: 14px;
    }
    
    .header{
        max-width: 900px;
        margin: auto auto;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 25px;
    }


    .goToHome{
        text-align: center;
        padding-top: 0px;
        cursor: pointer;
        &.withPaddingTop{
            padding-top: 20px;
        }
    }

    form{
        max-width: 630px;
        margin: auto auto;
        > * { padding: 35px;}
        
        > ul{ margin-bottom: 37px;}
        
        .hint{ color: grey; font-size: 12px;}

        .errorhint{color: #d01a91}
        .error .checkbox{ color: #d01a91}

        .error .radio_container {
            label {
                color: #d01a91;
            }
            .checkmark {
                border-color: #d01a91; 
            }
        }
        .error.active{
            .showIfError{
                &:before{
                    content: url(/images/active_error_arrow.png);
                    position: absolute;
                    margin-top: -24px;
                    margin-left: 8px;
                }
                display: block;
                color: white;
                background-color: $quirion-purple;
                margin-top: 6px;
                padding: 10px;
                padding-left: 15px;
                padding-right: 15px;
                font-size: 15px;
                position: absolute;
            }
        }
        .error{
            
            select,
            input{
                border: 1px solid #d01a91;
                color: #d01a91; 
            }
        }
    }
    h1{
        padding: 17px 40px;
        margin: 0px;
        font-size: 22px;
        background-color: $quirion-dark-blue;
        color: white;
        margin-top: 37px;
        
        @media screen and (max-width: $breakPointS) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    h2{ 
        background-color:#f0eef1;
        margin: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        font-weight: lighter;
        font-family: 'Roboto';
        padding-left: 0px;
    }
    .sepa{ padding-left: 30px;}
    .dontDisplayContent{ display: none;}
    ul{
        margin: 0px;
        background-color: #f0eef1;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 35px;
        padding-top: 0px;
        padding-bottom: 35px;
        
        @media screen and (max-width: $breakPointS) {
            padding-left: 20px;
            padding-right: 20px;
        }
        &.top{ padding-top: 35px;}
        
        li{
            list-style: none;
            ul
            {
                padding-left: 50px;
                padding-top: 25px;

                @media screen and (max-width: $breakPointS) {
                    padding-left: 22px;
                    padding-right: 20px;
                }                
                li{
                    list-style: disc;
                    font-size: 13px;
                }
            }
            width: calc(50% - 10px);
            padding: 5px;
            &.long{width: calc(75% - 10px);}
            &.short{width: calc(25% - 10px);}
            &.full{width: calc(100% - 10px);}
            &.break{width: calc(100% - 10px); height: 30px;}


            &.full_atBreakpointS{
                @media screen and (max-width: $breakPointS) {
                    width: calc(100% - 10px);
                }
            }

            flex-grow: 0;
            select,
            input{
                width: calc(100% - 20px);
                padding-left: 10px;
                padding-right: 10px;
                border: 0px;
                height: 35px;
                padding-top: 0px;
                padding-bottom: 0px;
                //border: 1px solid white;
                border-bottom: 1px solid #2daafa;
            }
            select{
                height: 36px;
                background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
                -moz-appearance: none; 
                -webkit-appearance: none; 
                appearance: none;                   
            }
            
            .checkbox_container{
                display: flex;
                padding-top: 3px;

                &.displayNone{
                    display: none;
                }

                .radio,
                .checkbox{
                    cursor: pointer;
                    display: inline-block;
                    min-height: 19px;
                    background-image: url(/images/checkbox_off.gif);
                    background-repeat: no-repeat;
                    background-position: left top;
                    &.selected{
                        background-image: url(/images/checkbox_on.gif)!important;
                        }
                    
                    label{
                        padding-right: 20px;
                        padding-left: 31px;
                        background: none;
                        cursor: pointer;
                    }
                }
            }
            &.hideContent{ visibility: hidden;}
            
            select{ width: calc(100%);background-color: white;cursor: pointer;}
        }
    }
    
    .buttonHolder{
        display: block;
        text-align: center;
        padding-top: 0px;
        .go_on{

            border: 1px solid #2daafa;
            padding: 17px 35px 17px 35px;
            margin: 10px;
            font-size: 20px;
            min-width: 200px;
            &.large{
                min-width: 290px;
            }
            display: inline-block;
            cursor: pointer;
            background-color: #2daafa;
            color: white;
            font-weight: lighter;
            &.light{
                background-color: white;
                color: #2daafa;
            }
        }
    }
    
    &.step4{
        @media screen and (max-width: $breakPointS) {
            h2{ font-size: 19px;}
        }

    }

    &.step5{
        .buttonHolder{
            text-align: left;
            padding-top: 5px;
            .go_on{
                margin: 0px;
                padding: 4px 15px 7px 15px;
                min-width: 150px;
                text-align: center;
            }
        }
    }
    
    .wrapper.steps{
        display: inline-flex;
        @media screen and (max-width: $breakPointM) {
            display: block;
        }
        flex-direction: column;
            
        >div:nth-of-type(1){
            display: inline-flex;
            width: 716px;
            margin: auto;
            @media screen and (max-width: $breakPointM) {
                width:auto;
            }
        }
        >div:nth-of-type(2){
            padding-top: 10px;
            font-size: 14px;
            display: inline-flex;
            width: 856px;
            margin: auto;
            @media screen and (max-width: $breakPointM) {
                width:auto;
            }
            >div{
                width: 170px;
            }
        }
        .label.attention,
        .label.done{
            cursor: pointer;
        }

        .label{
            @media screen and (max-width: $breakPointM) {
                display: none;
            }
        }

        .step{
            height: 32px;
            width: 32px;
            border-radius: 50%;
            display: inline-block;
            border:2px solid $quirion-coplor-step1;
            font-family: 'museo';
            font-size: 19px;
            
            >span{
                padding-top: 6px;
                display: inline-block;
                &.status{
                    padding: inherit;
                    display: inline-block;
                    height: 17px;
                    width: 17px;
                    position: absolute;
                    margin-top: -7px;
                    margin-left: -1px;                            
                }                 
            }
          
            &.done{
                cursor: pointer;
                .status{
                    background-image: url('/images/status_done.svg');
                }                
            }
            &.wrong .status{
                background-image: url('/images/status_wrong.svg');
            }
            &.attention{
                cursor: pointer;
                 .status{
                    background-image: url('/images/status_attention.svg');
                }
            }
            
          
            &.step1{
                border-color: $quirion-coplor-step1;
            }
            &.step2{
                border-color: $quirion-coplor-step2;
            }
            &.step3{
                border-color: $quirion-coplor-step3;
            }
            &.step4{
                border-color: $quirion-coplor-step4;
            }
            &.step5{
                border-color: $quirion-coplor-step5;
            }                  
        }

        .line{
            width: 134px;


            @media screen and (max-width: $breakPointM) {
                width: 11vw;
            }

            span{
                display: block;
                height: 2px;
                margin-top: 15px;
            }
            
            &.step1 span{
                background-image: linear-gradient(to right, $quirion-coplor-step1 , $quirion-coplor-step2);
            }
            &.step2 span{
                background-image: linear-gradient(to right, $quirion-coplor-step2 , $quirion-coplor-step3);
            }
            &.step3 span{
                background-image: linear-gradient(to right, $quirion-coplor-step3 , $quirion-coplor-step4);
            }
            &.step4 span{
                background-image: linear-gradient(to right, $quirion-coplor-step4 , $quirion-coplor-step5);
            }
        }
    }
}

.new_product{
    #registration{
        &.step4{
            .buttonHolder{
                display: block!important;
                text-align: center;
                >div{
                    width: 280px;
                    display: inline-block;
                }
            }
        }
    }
}

.costLink{
    a{ text-decoration: underline;}
}