body.robo{
    #header{display: none;}
}

#header{
    
    max-height:85px;
    background-position: center;
    z-index: 2;
    position: relative;    
    
    #homeLinkHolder{
        width: inherit;
         @media screen and (min-width: 1600px) {
            width: 1400px;        
        }
        @media screen and (min-width: 1700px) {
            width: 1500px;
        }
        @media screen and (min-width: 1800px) {
            width: 1600px;
        }
        @media screen and (min-width: 1900px) {
            width: 1700px;        
        }
        margin: auto auto;
        #homeLink{
            border: 0px solid red;
            width: 250px;
            height: 80px;
            @media screen and (max-width: $breakPointS) {
                width: 180px;
                height: 65px;
            }            
        }
        a{
            padding-top: 0px;
            position: absolute;
        }
    }

    &.open{
        #homeLinkHolder{
            display: none;
        }
    }
    
    .displayNone{
        display: none;
    }

    a{
        color: white;
        text-decoration: none;
        background-repeat: no-repeat;
        background-position: top;
        height: 26px;
        display: inline-block;
        padding-top: 23px;
        font-weight: lighter;
        font-size: 14px;
        background-size: 100% 17px;
    }
    
    p{ padding: 0px; margin: 0px;}
    
    .list a{background-image: url(/images/icons/list.svg);
        >div{
            background-color:$quirion-purple;
            border-radius: 10px;
            width: 21px;
            height: 18px;
            text-align:center;
            margin-top: -48px;
            margin-left: 35px;
            position:absolute;
            padding-top:3px;
            font-size:13px;
        }
    }
    .dashboard a{background-image: url(/images/icons/dashboard_white.svg);}
    .user a{background-image: url(/images/icons/settings_white.svg);}
    .message a{
        background-image: url(/images/icons/message.svg);
        >div{
            background-color:$quirion-purple;
            border-radius: 10px;
            width: 21px;
            height: 18px;
            text-align:center;
            margin-top: -48px;
            margin-left: 42px;
            position:absolute;
            padding-top:3px;
            font-size:13px;
            @media screen and (max-width: $mainMenuResponsiveWidth) {
                margin-top: -26px;
                margin-left: 80px;
            }
        }
        
    }
    .logout a{background-image: url(/images/icons/sign-out.svg);}
    
    .responsive{
        width:20px;
        height:20px;
        &.responsive_opener{
            background-image: url(/images/icons/icon_burger.svg);
            }
            
        &.logout{
            background-image: url(/images/icons/padlock.svg);
        }
    }
    
    ul{
        &.meta_menu{display: none;}
        
        width: inherit;
         @media screen and (min-width: 1600px) {
            width: 1400px;        
        }
        @media screen and (min-width: 1700px) {
            width: 1500px;
        }
        @media screen and (min-width: 1800px) {
            width: 1600px;
        }
        @media screen and (min-width: 1900px) {
            width: 1700px;        
        }
        margin: auto auto;
        color: white;
        border: 0px solid white;
        display: flex;
        flex-direction: row-reverse;
        height: 85px;
        li{
            list-style: none;
            padding-right: 40px;
            padding-top: 25px;
            
            &.responsive{
                display: none;
            }
            
            @media screen and (max-width: $mainMenuResponsiveWidth) {
                display: none;
                padding-right: 0px;
                padding-top: 0px;
                &.responsive{
                    display: inline-block;
                    margin-top: 32px;
                    margin-right: 40px;
                }
            }
            @media screen and (max-width: $breakPointS) {
                &.responsive{
                    margin-top: 20px;
                }
            }
        }
        
        @media screen and (max-width: $breakPointS) {
            height: 65px;
        }
    }
    
    &.open{
        @media screen and (max-width: $mainMenuResponsiveWidth) {
            
            .responsive_opener{
                position: absolute;
                top: 0px;
                right: 0px;
            }
            
            .logout.responsive{
                position: absolute;
                top: 0;
                right: 60px;                
            }
            
            //.holder{
                position: absolute;
                right: 0px;
                top: 0;
                bottom: 0;
                left: 0px;
                margin: auto;
                background-color: #2a1340; 
                max-height: inherit;
                left: 10vw;
            //}
            ul{
                flex-direction: column-reverse;
                margin-top: 100px;
                height: inherit;
                padding-inline-end: 40px;
                
                &.meta_menu{
                    display: block;
                    //display: none;
                    color: $quirion-dark-grey;
                    margin-top: 40px;
                    li{
                        border: 0px;
                        padding-bottom: 15px;
                    }
                }
                
                li{
                    display: block;
                    text-align: left;
                    border-bottom: 1px solid white;
                    padding-bottom: 12px;
                    //margin-right: 10vw;
                    &.responsive{
                        border-bottom: 0px;
                        margin-right: 40px;
                        padding-bottom: 0px;
                    }
                    a{
                        padding-left: 35px;
                        color: white;
                        text-decoration: none;
                        background-repeat: no-repeat;
                        background-position: left 20px;
                        height: 26px;
                        display: inline-block;
                        padding-top: 23px;
                        font-weight: lighter;
                        font-size: 14px;
                        background-size: 20px 20px;
                    }
                }
            }
        }
    }
}

