.dialog_holder{
    .buttonHolder{
        text-align: center;
        font-size: 12px;
        margin-top: 30px;
        > div{
            display: inline-block;
        }
    }

    .back{ padding-bottom: 60px; cursor: pointer;}
}

.dialog{
    
    
    max-width: 630px;
    &.big{
        max-width: 960px;
    }
    background-color: #f0eef1;
    display: block;
    margin: auto auto;
    margin-top: 35px;
    font-size: 13px;
    line-height: 17px;
    
    .showIfError{ display: none;}
    
    h1{
        height: 30px;
        background-color: $quirion-dark-blue;
        color: white;
        font-family: 'museo';
        font-weight: normal;
        padding: 23px 40px 7px;
        margin: 0px;

        @media screen and (max-width: $breakPointS) {
            height: auto;
            min-height: 30px;
            line-height: 28px;
            padding-top: 11px;
        }
    }
    
    form{
        > * { padding: 35px;}
        
        .error .checkbox{ color: #d01a91}
        
        .error.active{
            .showIfError{
                &:before{
                    content: url(/images/active_error_arrow.png);
                    position: absolute;
                    margin-top: -24px;
                    margin-left: 8px;
                }
                display: block;
                color: white;
                background-color: #d01a91;
                margin-top: 6px;
                padding: 10px;
                padding-left: 15px;
                padding-right: 15px;
                font-size: 15px;
                position: absolute;
            }
        }
        .error{
            select,
            input{
                border: 1px solid #d01a91;
            }
        } 
    }
    
    .buttonHolder{
        padding-top: 0px;
        display: block;
        text-align: center;
        >div{
            border: 1px solid $quirion-dark-blue;
            padding: 8px 15px 8px 15px;
            margin: 10px;
            font-size: 15px;
            min-width: 125px;
            display: inline-block;
            cursor: pointer;

            color: $quirion-dark-blue;
            font-weight: lighter;
            &.light{
                background-color: white;
                color: #2daafa;
            }
        }
        
        &.buttonSize190{

            .loading .spinner{
                widows: 21px;
                height: 21px;
                margin-left: -34px;
                margin-top: -2px;
                background-size: 21px 21px;
            }
            >div{
                min-width: 190px;
            }
        }

        .go_on{
            border: 1px solid #2daafa;
            background-color: #2daafa;
            color: white;
        }
    }   
     
    
    .label{ display: block; padding-bottom: 5px;}
    
    strong{
        span{ font-weight: normal;}
    }
    
    .sepa_bank_info{
        line-height: 20px;
        >div{
            &:nth-of-type(1){
                >div{
                    padding-bottom: 20px;
                }
            }
            //display: flex;
            >div:nth-of-type(1){ font-weight: bold; padding-left: 30px; padding-right: 50px;}  
        } 
    }
    
    .sepa p{ padding-left: 30px;}
    
    .radio.dontDisplayContent{ display: none!important;}
    
    ul{
        margin: 0px;
        //background-color: #f0eef1;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 35px;
        padding-top: 0px;
        padding-bottom: 35px;
        
        &.top{ padding-top: 35px;}
        &.dontDisplayContent{ display: none;}
        li{
            list-style: none;
            width: calc(50% - 10px);
            padding: 5px;
            &.long{width: calc(75% - 10px);}
            &.short{width: calc(25% - 10px);}
            &.full{width: calc(100% - 10px);}
            &.break{width: calc(100% - 10px); height: 30px;}


            &.full_atBreakpointS{
                @media screen and (max-width: $breakPointS) {
                    width: calc(100% - 10px);
                }
            }            

            flex-grow: 1;
            select,
            input{
                width: calc(100% - 20px);
                padding-left: 10px;
                padding-right: 10px;
                border: 0px;
                height: 32px;
                border: 1px solid white;
                border-bottom: 1px solid #2daafa;
                overflow: scroll;
            }
            select{
                height: 36px;
                &:disabled{
                    color: black;
                }
            }
            .checkbox_container{
                display: flex;
                padding-top: 3px;
                .radio,
                .checkbox{
                    cursor: pointer;
                    display: inline-block;
                    min-height: 19px;
                    background-image: url(/images/checkbox_off.gif);
                    background-repeat: no-repeat;
                    background-position: left top;
                    &.selected{
                        background-image: url(/images/checkbox_on.gif)!important;
                        }
                    
                    label{
                        padding-right: 20px;
                        padding-left: 30px;
                        background: none;
                        cursor: pointer;
                    }
                }
            }
            &.hideContent{ visibility: hidden;}
            &.dontDisplayContent{ display: none;}
            
            select{ width: calc(100%);background-color: white;cursor: pointer;}
        }
    }

    &#StrategyChange .hint{
        padding-top: 0px;
        li{ padding-bottom: 10px;}
    }

    .info_ueberweisung{
        background-color: white;
        text-align: center;
        margin: 20px 5px 5px 5px;
        padding: 10px 30px;
        font-size: 15px;
        line-height: 23px;
   }  
        
    .info_verrechnung{
        background-color: white;
        text-align: center;
        margin: 20px 5px 5px 5px;
        padding: 10px 30px;
        font-size: 15px;
        line-height: 23px;
    }
    
    &#postbox{
        ul.top{
            padding-bottom: 10px;
        }
        .buttonHolder{ padding-bottom: 30px;}
        .table.docs{
            padding-top: 0px;
             >div{
                 cursor: pointer;
                    &.unread{
                         font-weight: bold;
                    }
                    font-size: 11px;
                    &:first-of-type{
                        font-weight: bold;
                    }
                    >div:nth-of-type(1){
                        padding-right: 20px;
                    }
                    
                    >div{
                        border-bottom: 1px solid $quirion-dark-grey;
                        text-align: left;
                        padding: 6px 0px;
                        &:last-of-type{
                            text-align: left;
                            width: 99%;
                            word-break: break-all;
                        }
                    }
                }              

        }
    }
    
    &#savingsplans,
    &#orders{
        ul.top{
            padding-bottom: 10px;
        }
        .buttonHolder{ padding-bottom: 30px;}
        .table.elements{
            padding-top: 0px;
            min-width: calc(100% - 70px);
             >div{
                    &.unread{
                         font-weight: bold;
                    }
                    font-size: 11px;
                    &:first-of-type{
                        font-weight: bold;
                    }
                    >div:nth-of-type(1){
                        padding-right: 20px;
                    }
                    
                    >div{
                        border-bottom: 1px solid $quirion-dark-grey;
                        text-align: left;
                        padding: 6px 0px;
                        width: 20%;
                        &:first-of-type{
                            text-align: left;
                            width: 40%;
                        }
                    }
                }
        }
    }
    &#savingsplans{

        &.new_edit_form .buttonHolder{
            margin-top: 0px;
        }

        .table.elements{
            padding-top: 35px;
            >div >div{
                vertical-align: middle;
            }

            >div >div:nth-of-type(2),
            >div >div:nth-of-type(3){
                padding-right: 5px;
                padding-left: 5px;
            }

            >div >div:nth-of-type(2),
            >div >div:nth-of-type(3),
            >div >div:nth-of-type(4){
                text-align: right;
                width: 14%;
            }
            >div >div:nth-of-type(3){
                color: $color-robo-blue;
                font-weight: bold;
            }
            >div:nth-of-type(1) >div:nth-of-type(3){
                color: black;
            }


            @media screen and (max-width: $breakPointS) {
                >div >div:nth-of-type(4){
                    display: none!important;
                }
            }

        }

        .button.new{
            min-width: inherit;
            font-size: 12px;
            height: inherit;
            padding: 10px 35px 10px 14px;
            margin-bottom: 8px;
            text-align: left;
            background-repeat: no-repeat;
            background-position: right 10px center;
            background-size: 13px 13px;            
            background-image: url(/images/icons/cross_white.svg);
            width: 130px;
            background-color: $color-robo-blue;
            border: 1px solid $color-robo-blue;
            color: white;
            margin-right: 10px;            
        }


    }

    &#money_out_reference{
        .table{
            padding-top: 20px;
            >div >div:nth-of-type(1){
                font-weight: bold;
                padding-right: 20px;
            }
        }
        .freeAmount{
            padding: 8px;
            background-color: $text-color-robo;
            color: white;
            margin: 5px;
            text-align: center;
            font-size: 16px;
            span{
                color: $color-robo-blue;
                font-weight: bold;
            }
        }

        .openReserve{
            padding-bottom: 20px;
            h2{
                margin-bottom: 0px;
                font-size: 13px;
                font-weight: bold;
            }
            .table{
                padding-top: 4px;
            }
        }
    }

    .whyButton{
        color:$color-robo-blue;
    }
}