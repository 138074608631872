body.robo.new_product{
    overflow: inherit;
    #header{display: inherit;} 
    
    .sliderElement{
        top: inherit!important;
        z-index: 1!important;
    
    
    
        .advise{
            margin-top: 30px!important;
        }
    
    }   

    #registration,
    #new_product{
        
        form > ul{
            margin-bottom: 0px;
        }
        
        &.step{
            form{
                input[type="text"]{
                    background-color: white;
                    font-size: inherit;
                }
                
                ul.top{
                    margin-bottom: 37px;
                }
                
                a{color: #2daafa;}
                
                .checkbox_container.important {
                    background-color: #2a1340;
                    color: white;
                    padding: 10px;
                }                
                
                .extraTopMargin {
                    margin-top: 40px;
                }                
            }
            
            &.step4{
                h2{ margin-bottom: 20px;}
            }
        }
        
        .advise_container{
            margin-bottom: 35px;
        }
        
        .header{
            max-width: 900px;
            margin: auto auto;
            text-align: center;
            margin-top: 40px;
            margin-bottom: 25px;
            
            .wrapper.steps{
                display: inline-flex;
                flex-direction: column;
                    
                >div:nth-of-type(1){
                    display: inline-flex;
                    width: 677px;
                    margin: auto;
                }
                >div:nth-of-type(2){
                    padding-top: 10px;
                    font-size: 14px;
                    display: inline-flex;
                    width: 856px;
                    margin: auto;
                    >div{
                        width: 213px;
                    }
                }
                .label.attention,
                .label.done{
                    cursor: pointer;
                }
                .step{
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    display: inline-block;
                    border:2px solid $quirion-coplor-step1;
                    font-family: 'museo';
                    font-size: 19px;
                    
                    >span{
                        padding-top: 6px;
                        display: inline-block;
                        &.status{
                            padding: inherit;
                            display: inline-block;
                            height: 17px;
                            width: 17px;
                            position: absolute;
                            margin-top: -7px;
                            margin-left: -1px;                            
                        }                 
                    }
                  
                    &.done{
                        cursor: pointer;
                        .status{
                            background-image: url('/images/status_done.svg');
                        }                
                    }
                    &.wrong .status{
                        background-image: url('/images/status_wrong.svg');
                    }
                    &.attention{
                        cursor: pointer;
                         .status{
                            background-image: url('/images/status_attention.svg');
                        }
                    }
                    
                  
                    &.step1{
                        border-color: $quirion-coplor-step1;
                    }
                    &.step2{
                        border-color: $quirion-coplor-step2;
                    }
                    &.step3{
                        border-color: $quirion-coplor-step3;
                    }
                    &.step4{
                        border-color: $quirion-coplor-step4;
                    }
                    &.step5{
                        border-color: $quirion-coplor-step5;
                    }                  
                }
                
                .line{
                    width: 187px;
                    span{
                        display: block;
                        height: 2px;
                        margin-top: 15px;
                    }
                    
                    &.step1 span{
                        background-image: linear-gradient(to right, $quirion-coplor-step1 , $quirion-coplor-step2);
                    }
                    &.step2 span{
                        background-image: linear-gradient(to right, $quirion-coplor-step2 , $quirion-coplor-step3);
                    }
                    &.step3 span{
                        background-image: linear-gradient(to right, $quirion-coplor-step3 , $quirion-coplor-step4);
                    }
                    &.step4 span{
                        background-image: linear-gradient(to right, $quirion-coplor-step4 , $quirion-coplor-step5);
                    }
                }
            }
        }
        .buttonHolder{
            display: inline-flex;
            margin-top: 5px;
            padding-bottom: 0px;
            padding-top: 0px;
            div{
                border: 1px solid #2a1340;
                margin: 10px;
                font-size: 19px;                
                a{
                    padding: 12px 5px 13px 5px;
                    min-width: 250px;
                    display: inline-block;
                    cursor: pointer;
                    color: #2a1340;
                    background-color: transparent;
                }
            }
            
            &.unimportant{
                margin-top: 0px;
                display: flex;
                flex-direction: column;
                div{
                    border: 0px;
                    a{
                        font-size: 17px;                    
                    }
                }
            }
        }
    }
}